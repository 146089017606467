








































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import LeadGenerationForm from '@/components/lead/LeadGenerationForm.vue'
import OfferingModel from '@/models/offering/offering-model'
import ProviderModel from '@/models/provider/provider-model'
import ROUTE_NAMES from '@/router/route-names'
import DividerWithText from '@/components/form/DividerWithText.vue'

@Component({
  components: {
    LeadGenerationForm,
    DividerWithText,
  },
})
export default class GetStartedForm extends Vue {
  @Prop() isLogBoxPractice!: boolean

  @Prop() offering!: OfferingModel

  @Prop() provider!: ProviderModel

  ROUTES = ROUTE_NAMES
}
