



































































































































import {
  Component, Model, Ref, Watch,
} from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { namespace } from 'vuex-class'
import GradeModel from '@/models/needscalculator/grade-model'
import PracticeTypeModel from '@/models/needscalculator/practice-type-model'
import SelectField from '@/components/form/SelectField.vue'
import TextField from '@/components/form/TextField.vue'
import FormCard from '@/components/form/FormCard.vue'
import CalculatorFormModel from '@/models/needscalculator/needs-calculator-form-model'
import RecommendationsModel from '@/models/needscalculator/recommendations-model'
import validationMixin from '@/mixins/validation-mixin'
import RouteNames from '@/router/route-names'
import ValidationModel from '@/models/validation/validation-model'
import LeadActionFormModel from '@/models/lead/lead-action-form-model'
import LeadModel from '@/models/lead/lead-model'
import LeadActionModel from '@/models/lead/lead-action-model'
import { VFormType } from '@/types/v-form-type'
import TsAndCsCheckbox from '@/components/lead/TsAndCsCheckbox.vue'
import RangeTagModel from '@/models/offering/tags/range-tag-model'
import TextTagModel from '@/models/offering/tags/text-tag-model'
import SpecialityModel from '@/models/needscalculator/speciality-model'
import ProfileModel from '@/models/profile/profile-model'

const NeedsCalculatorFormModule = namespace('needsCalculatorFormStore')
const LeadModule = namespace('leadStore')
const ProfileModule = namespace('profileStore')

@Component({
  components: {
    TsAndCsCheckbox,
    FormCard,
    TextField,
    SelectField,
  },
})
export default class PersonalNeedsBasedCalculator extends mixins(validationMixin) {
  @Model('close') display!: boolean

  @Ref('calculatorFormRef') calculatorFormRef!: VFormType

  @ProfileModule.State('loggedIn')
  loggedIn !: boolean

  @ProfileModule.State('userProfile')
  userProfile !: ProfileModel

  @NeedsCalculatorFormModule.State('practiceTypes')
  practiceTypes!: PracticeTypeModel[]

  @NeedsCalculatorFormModule.State('grades')
  grades!: GradeModel[]

  @NeedsCalculatorFormModule.State('specialities')
  specialities!: SpecialityModel[]

  @NeedsCalculatorFormModule.State('recommendations')
  recommendationsModel!: RecommendationsModel

  @NeedsCalculatorFormModule.State('validation')
  validationModel!: ValidationModel

  @NeedsCalculatorFormModule.Action('clearValidation')
  clearValidation!: Function

  @NeedsCalculatorFormModule.Action('updatePracticeTypes')
  updatePracticeTypes!: Function

  @NeedsCalculatorFormModule.Action('updateGrades')
  updateGrades!: Function

  @NeedsCalculatorFormModule.Action('updateSpecialities')
  updateSpecialities!: Function

  @LeadModule.State('path')
  leadPath!: LeadActionModel[]

  @LeadModule.Mutation('addToPath')
  addToPath!: Function

  @LeadModule.State('leadTrack')
  leadTrack!: LeadModel

  @NeedsCalculatorFormModule.Action('submitForm')
  submitForm!: Function

  private calculatorForm: CalculatorFormModel = new CalculatorFormModel()

  get form(): VFormType {
    return this.$refs.calculatorFormRef as VFormType
  }

  created() {
    this.updatePracticeTypes()
    this.updateGrades()
    this.updateSpecialities()
    this.clearValidation()
    this.setCalculatorForm()
  }

  setCalculatorForm() {
    this.calculatorForm = { ...this.recommendationsModel.needsCalculatorForm }
    this.calculatorForm.firstName = this.userProfile.firstName
    this.calculatorForm.lastName = this.userProfile.lastName
    this.calculatorForm.emailAddress = this.userProfile.emailAddress
  }

  async submit() {
    if (this.form.validate()) {
      this.calculatorForm.age = parseInt(this.calculatorForm.age.toString(), 10)
      this.createOfferingTagsFromForm()
      if (JSON.stringify(this.calculatorForm)
        !== JSON.stringify(this.recommendationsModel.needsCalculatorForm)) {
        await this.submitForm(this.calculatorForm)
      }

      if (this.validationModel.errors.length === 0) {
        const formPath = new LeadActionFormModel()
        formPath.type = 'need_form'
        formPath.needsCalculatorFormID = Number(this.recommendationsModel.needsCalculatorForm.id)

        this.addToPath(formPath)

        this.close()
        if (this.$router.currentRoute.name !== RouteNames.RECOMMENDATIONS) {
          await this.$router.push({ name: RouteNames.RECOMMENDATIONS })
        }
      }
    }
  }

  createOfferingTagsFromForm() {
    this.calculatorForm.tags = []
    this.calculatorForm.tags.push(
      new TextTagModel(this.calculatorForm.practiceType.name, 'practiceType'),
      new TextTagModel(this.calculatorForm.grade.name, 'grade'),
      new TextTagModel('priority', 'priority'),
      new RangeTagModel(this.calculatorForm.age, 'age'),
    )
  }

  close() {
    this.clearValidation()
    this.setCalculatorForm()
    this.$emit('close', false)
    return false
  }

  @Watch('userProfile', { deep: true })
  updateFormModel() {
    if (this.display) {
      this.setCalculatorForm()
    }
  }
}
