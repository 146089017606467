








import { Component, Prop } from 'vue-property-decorator'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'
import { mixins } from 'vue-class-component'

@Component
export default class ProviderCategoryDiagram extends mixins(EnvironmentUrlMixin) {
  @Prop() illustrationPath!: string
}
