import UpdateProfileRequestModel from '@/models/profile/update-profile-request-model'
import ProfileModel from '@/models/profile/profile-model'
import axios from 'axios'

export default {
  async getProfile(): Promise<ProfileModel> {
    const response = await axios.get('/api/identity/', { withCredentials: true })
    const profile = Object.assign(
      new ProfileModel(),
      response.data,
    ) as ProfileModel
    if (!profile.profileImage) {
      profile.profileImage = new ArrayBuffer(0)
    }
    if (!profile.hpcsaDocumentImage) {
      profile.hpcsaDocumentImage = new ArrayBuffer(0)
    }
    if (!profile.idDocumentImage) {
      profile.idDocumentImage = new ArrayBuffer(0)
    }
    return profile
  },

  async postUpdateProfile(updateProfileRequest: UpdateProfileRequestModel): Promise<ProfileModel> {
    const response = await axios.post('/api/identity/update', updateProfileRequest, { withCredentials: true })
    const profile = Object.assign(
      new ProfileModel(),
      response.data,
    ) as ProfileModel

    if (!profile.profileImage) {
      profile.profileImage = new ArrayBuffer(0)
    }
    if (!profile.hpcsaDocumentImage) {
      profile.hpcsaDocumentImage = new ArrayBuffer(0)
    }
    if (!profile.idDocumentImage) {
      profile.idDocumentImage = new ArrayBuffer(0)
    }

    return profile
  },

  async postVerifyHpcsaDetails(data: FormData): Promise<Map<string, boolean>> {
    const response = await axios.post('/api/identity/verify/hpcsa-details', data, { withCredentials: true })
    return response.data
  },

  async postVerifyIdDetails(data: FormData): Promise<Map<string, boolean>> {
    const response = await axios.post('/api/identity/verify/id-details', data, { withCredentials: true })
    return response.data
  },

  async postVerifyEmailAddress(): Promise<number> {
    const response = await axios.post('/api/identity/verify/email-address', {}, { withCredentials: true })
    return response.status
  },
}
