















































































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CompanyGeneralLinks extends Vue {
  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }
}
