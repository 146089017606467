

























import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ErrorModel from '@/models/error-model'

const ErrorModule = namespace('errorStore')

@Component({})
export default class ErrorComponent extends Vue {
  @ErrorModule.State('error') error!: ErrorModel

  @ErrorModule.Mutation('hide') hide!: Function

  @Watch('$route')
  onUrlChange() {
    this.hide()
  }
}

