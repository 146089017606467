





































import { Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { mixins } from 'vue-class-component'
import CategoryModel from '@/models/offering/category-model'
import screenSizeMixin from '@/mixins/screen-size-mixin'

const CategoryModule = namespace('categoryStore')

@Component
export default class CategoriesNavigation extends mixins(screenSizeMixin) {
  @CategoryModule.State('categories')
  categories!: CategoryModel[]

  @CategoryModule.Action('updateCategories')
  loadCategories!: Function

  async created() {
    this.loadCategories()
  }
}
