

















import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'

@Component
export default class ErrorDialog extends Vue {
  @Prop({ type: Boolean }) displayDialog!: boolean

  display!: boolean

  created() {
    this.display = this.displayDialog
  }

  @Watch('displayDialog')
  toggleDisplay() {
    this.display = this.displayDialog
  }

  closeDialogBox() {
    this.$emit('closeDialog')
    this.display = false
  }
}
