

































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { STEPS } from '@/services/verification-steps-service'
import FormCard from '@/components/form/FormCard.vue'
import ErrorDialog from '@/components/dialog/ErrorDialog.vue'
import VerificationProfilePicture from '@/components/verification/VerificationPicture.vue'
import VerificationID from '@/components/verification/VerificationID.vue'
import VerificationProfile from '@/components/verification/VerificationProfile.vue'
import VerificationPractice from '@/components/verification/VerificationPractice.vue'
import VerificationHPCSA from '@/components/verification/VerificationHPCSA.vue'
import RouteNames from '@/router/route-names'
import ProfileModel from '@/models/profile/profile-model'

const ProfileModule = namespace('profileStore')

@Component({
  components: {
    VerificationHpcsa: VerificationHPCSA,
    FormCard,
    ErrorDialog,
    VerificationProfile,
    VerificationPractice,
    VerificationProfilePicture,
    VerificationId: VerificationID,
  },
})
export default class Verification extends Vue {
  @ProfileModule.State('userProfile')
  userProfile!: ProfileModel

  @ProfileModule.State('loggedIn')
  loggedIn!: ProfileModel

  @ProfileModule.Mutation('updateUserProfile')
  updateUserProfile!: Function

  @ProfileModule.Action('updateProfileRequest')
  updateProfileRequest!: Function

  @ProfileModule.Action('fetchProfile')
  fetchProfile!: Function

  @Prop({ default: 0 }) startStep!: number

  @Prop({ type: Boolean, default: true }) inView!: boolean

  @Prop({ type: Boolean }) inStepper!: boolean

  displayErrorDialog = false

  progress = 0

  stepIndex = 0

  numberOfSteps = 0

  verificationError = 'The ID number on the image and the ID number given do not match'

  steps = STEPS

  currentSteps: string[] = []

  async created() {
    if (this.userProfile.isDefault()) {
      await this.fetchProfile()
    }
    if (
      this.userProfile.isHpcsaDocumentVerified
      && this.userProfile.isIdPassportDocumentVerified
      && this.userProfile.isEmailAddressVerified
    ) {
      await this.$router.replace(
        { name: RouteNames.PROFILE_WITH_NOTIFICATION },
      )
    }
    this.currentSteps = Object.keys(this.steps)
    this.currentSteps = this.currentSteps.slice(this.startStep)
    this.numberOfSteps = this.currentSteps.length
    this.progress = this.stepIndex * (100 / this.numberOfSteps)
  }

  nextStep() {
    if (this.progress >= 100) {
      if (this.inStepper) {
        this.$emit('next')
      } else {
        this.$router.push(
          { name: RouteNames.PROFILE_WITH_NOTIFICATION },
        )
      }
    } else {
      this.progress += 100 / this.numberOfSteps
      this.stepIndex += 1
    }
  }

  complete(verified: boolean) {
    if (this.progress <= 100) {
      this.progress = 100
      if (verified) {
        this.$emit('verified', true)
      }
    }
  }

  async previousStep() {
    this.progress -= 100 / this.numberOfSteps
    this.stepIndex -= 1

    if (this.stepIndex < 0) {
      if (this.inStepper) {
        this.$emit('cancelled')
        this.stepIndex = 0
      } else {
        await this.$router.push(
          { name: RouteNames.PROFILE_WITH_NOTIFICATION },
        )
      }
    }
  }

  async skipVerification() {
    if (this.inStepper) {
      this.$emit('cancelled')
      this.stepIndex = 0
    } else {
      await this.$router.push(
        { name: RouteNames.PROFILE_WITH_NOTIFICATION },
      )
    }
  }

  showErrors(errorMessage: string) {
    this.displayErrorDialog = true
    this.verificationError = errorMessage
  }

  @Watch('loggedIn')
  async checkIfLoggedIn() {
    if (!this.loggedIn) {
      await this.$router.replace({
        name: RouteNames.LOGIN,
      })
    }
  }
}
