
































import {
  Component, Prop, Vue,
} from 'vue-property-decorator'

@Component
export default class TextArea extends Vue {
  @Prop() readonly label!: string

  @Prop() readonly value!: string

  @Prop({ type: Boolean }) dark!: boolean

  @Prop({ default: 'text' }) readonly type!: string

  @Prop() rules!: Array<Function>
}
