





















































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import ImageUpload from '@/components/verificationuploadslots/ImageUpload.vue'
import verifyResponseProcessingService from '@/services/verify-response-processing-service'
import IconWithText from '@/components/form/IconWithText.vue'
import ProfileModel from '@/models/profile/profile-model'
import { namespace } from 'vuex-class'

const ProfileModule = namespace('profileStore')

@Component({
  components: { IconWithText, ImageUpload },
})
export default class VerifyDocumentUpload extends Vue {
  @ProfileModule.State('verificationResponse')
  verificationResponse!: Map<string, boolean>

  @ProfileModule.Mutation('updateUserProfile')
  updateUserProfile!: Function

  @ProfileModule.Action('postVerificationInformation')
  postVerificationInformation!: Function

  @Prop({ required: true }) identityProfile!: ProfileModel

  @Prop() hpcsaNumber!: string

  @Prop({ type: Boolean }) isHpcsa!: boolean

  @Prop({ type: Boolean }) uploadSlotOnly!: boolean

  isVerifying = false

  isVerified = false

  errors: string[] = []

  get documentImage(): ArrayBuffer {
    if (this.isHpcsa) {
      return this.identityProfile.hpcsaDocumentImage
    }
    return this.identityProfile.idDocumentImage
  }

  set documentImage(image: ArrayBuffer) {
    if (this.isHpcsa) {
      this.identityProfile.hpcsaDocumentImage = image
    } else {
      this.identityProfile.idDocumentImage = image
    }
  }

  get label(): string {
    if (this.uploadSlotOnly) {
      return ''
    }
    if (this.isHpcsa) {
      return 'Upload a copy of your HPCSA document'
    }
    return 'Upload a copy of your ID book, smart ID card, or Passport'
  }

  get title(): string {
    if (this.uploadSlotOnly) {
      return ''
    }
    if (this.isHpcsa) {
      return 'HPCSA'
    }
    return 'ID Verification'
  }

  get verified() {
    if (this.isHpcsa) {
      return this.identityProfile.isHpcsaDocumentVerified
    }
    return this.identityProfile.isIdPassportDocumentVerified
  }

  set verified(status: boolean) {
    this.isVerified = status
    if (this.isHpcsa) {
      this.identityProfile.isHpcsaDocumentVerified = status
    } else {
      this.identityProfile.isIdPassportDocumentVerified = status
    }
  }

  created() {
    this.isVerified = this.verified
    this.validateFields()
  }

  validateFields() {
    this.errors = []
    if (!(this.identityProfile.profileImage.byteLength > 0)) {
      this.errors.push('Profile picture required to verify')
    }
    if (this.identityProfile.firstName === '') {
      this.errors.push('First name required to verify')
    }
    if (this.identityProfile.lastName === '') {
      this.errors.push('Last name required to verify')
    }
    if (this.identityProfile.idPassport === '') {
      this.errors.push('ID or passport number required to verify')
    }
    if (this.isHpcsa) {
      if (this.identityProfile.hpcsaNumber === '') {
        this.errors.push('HPCSA number required to verify')
      }
    }
  }

  @Watch('identityProfile.hpcsaDocumentImage')
  watchHpcsaDocumentImage() {
    if (this.isHpcsa) {
      this.verified = false
      this.validateFields()
    }
  }

  @Watch('identityProfile.idDocumentImage')
  watchIdDocumentImage() {
    if (!this.isHpcsa) {
      this.verified = false
      this.validateFields()
    }
  }

  @Watch('identityProfile.profileImage')
  watchProfileImage() {
    this.verified = false
    this.validateFields()
  }

  @Watch('identityProfile.firstName')
  watchFirstName() {
    this.verified = false
    this.validateFields()
  }

  @Watch('identityProfile.lastName')
  watchLastName() {
    this.verified = false
    this.validateFields()
  }

  @Watch('identityProfile.idNumber')
  watchIdNumber() {
    this.verified = false
    this.validateFields()
  }

  @Watch('identityProfile.hpcsaNumber')
  watchHpcsaNumber() {
    if (this.isHpcsa) {
      this.verified = false
      this.validateFields()
    }
  }

  validated(): boolean {
    let validHpcsa = true
    if (this.isHpcsa) {
      validHpcsa = this.identityProfile.hpcsaNumber !== ''
    }
    return this.documentImage.byteLength > 0
      && this.identityProfile.profileImage.byteLength > 0
      && this.identityProfile.firstName !== ''
      && this.identityProfile.lastName !== ''
      && this.identityProfile.idPassport !== ''
      && validHpcsa
  }

  async verify() {
    this.isVerifying = true
    this.$emit('verifying')
    try {
      this.updateUserProfile(this.identityProfile)
      await this.postVerificationInformation(this.isHpcsa)
      this.isVerifying = false
      this.errors = verifyResponseProcessingService(
        new Map(Object.entries(this.verificationResponse)),
      )
      this.verified = this.errors.length === 0
      if (this.verified) {
        this.$emit('verified')
      } else {
        if (this.uploadSlotOnly) {
          this.errors.push('<br/> Please make sure you are uploading the correct document')
        }
        this.$emit('error', this.errors.join('<br/>'))
      }
    } catch (error) {
      this.$emit('error', 'Oops something went wrong on our side.<br/> Please try again later.')
      if (!this.uploadSlotOnly) {
        this.errors.push('Oops something went wrong on our side.', 'Please try again later.')
      }
      this.isVerifying = false
    }
  }
}
