export default class SignUpFormModel {
  firstName = ''

  lastName = ''

  username = ''

  emailAddress = ''

  mobileNumber = ''

  idNumber: string | undefined = undefined

  passportNumber: string | undefined = undefined

  password = ''

  confirmPassword = ''

  discipline = ''

  hpcsaNumber = ''

  practiceNumber = ''

  practicePhoneNumber = ''

  termsAndConditions = false
}
