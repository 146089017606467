import { RouteConfig } from 'vue-router'
import { LOGBOX_ACADEMIC_URL, LOGBOX_PRACTICE_URL } from '@/services/logbox-service'
import Home from '@/views/Home.vue'
import Category from '@/views/Category.vue'
import SignUp from '@/views/SignUp.vue'
import About from '@/views/About.vue'
import Login from '@/views/Login.vue'
import PasswordReset from '@/views/PasswordReset.vue'
import PasswordManagement from '@/views/PasswordManagement.vue'
import DetailPage from '@/components/offering/detail/DetailPage.vue'
import Broker from '@/components/lead/Broker.vue'
import Recommendations from '@/views/Recommendations.vue'
import ContactUs from '@/views/ContactUs.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import TermsAndConditions from '@/views/TermsAndConditions.vue'
import Verification from '@/views/Verification.vue'
import { STEPS } from '@/services/verification-steps-service'
import Profile from '@/views/Profile.vue'
import VerificationSteppers from '@/views/VerificationSteppers.vue'
import RouteNames from '@/router/route-names'
import NewPassword from '@/views/NewPassword.vue'
import UpdateProfile from '@/views/UpdateProfile.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: RouteNames.HOME,
    component: Home,
  },
  {
    path: '/about',
    name: RouteNames.ABOUT,
    component: About,
  },
  {
    path: '/careers',
    name: RouteNames.CAREERS,
  },
  {
    path: '/contact',
    name: RouteNames.CONTACT,
    component: ContactUs,
    props: (route) => ({
      recommendedOfferings: route.params.recommendedOfferings,
    }),
  },
  {
    path: '/disclaimer',
    name: RouteNames.DISCLAIMER,
  },
  {
    path: '/privacy-policy-cookies',
    name: RouteNames.COOKIES_EXPLANATION,
    beforeEnter() {
      window.open('https://www.freeprivacypolicy.com/blog/cookies/', '_blank')
    },
  },
  {
    path: '/category/:categoryId-:categoryName',
    name: RouteNames.CATEGORY,
    component: Category,
    props: (route) => ({
      categoryId: route.params.categoryId,
      categoryName: route.params.categoryName,
    }),
  },
  {
    path: '/broker/:providerName',
    name: RouteNames.BROKER,
    component: Broker,
    props: (route) => ({
      providerName: route.params.providerName,
    }),
  },

  {
    path: '/category/:categoryId-:categoryName/offerings/:offeringId',
    name: RouteNames.OFFERING,
    component: DetailPage,
    props: (route) => ({
      categoryId: route.params.categoryId,
      offeringId: route.params.offeringId,
      categoryName: route.params.categoryName,
    }),
    beforeEnter: (to, from, next) => {
      if (to.params.offeringId != null) {
        next()
      }
    },
  },
  {
    path: '/help',
    name: RouteNames.HELP,
  },
  {
    path: '/login',
    name: RouteNames.LOGIN,
    component: Login,
  },
  {
    path: '/new-password',
    name: RouteNames.NEW_PASSWORD,
    component: NewPassword,
  },
  {
    path: '/orders',
    name: RouteNames.ORDERS,
  },
  {
    path: '/password-management',
    name: RouteNames.PASSWORD_MANAGEMENT,
    component: PasswordManagement,
  },
  {
    path: '/password-reset',
    name: RouteNames.PASSWORD_RESET,
    component: PasswordReset,
  },
  {
    path: '/payments',
    name: RouteNames.PAYMENTS,
  },
  {
    path: '/privacy-policy',
    name: RouteNames.PRIVACY_POLICY,
    component: PrivacyPolicy,
  },
  {
    path: '/recommendations',
    name: RouteNames.RECOMMENDATIONS,
    component: Recommendations,
  },
  {
    path: '/sign-up',
    name: RouteNames.SIGN_UP,
    component: SignUp,
  },
  {
    path: '/sign-up/verification-email-sent',
    name: RouteNames.SIGN_UP_VERIFICATION_EMAIL_SENT,
    component: VerificationSteppers,
    props: {
      status: false,
    },
  },
  {
    path: '/sign-up/verification',
    name: RouteNames.VERIFICATION_STEPPER,
    component: VerificationSteppers,
    props: (route) => ({
      status: (route.query.status === 'true'),
    }),
  },
  {
    path: '/terms-and-conditions',
    name: RouteNames.TERMS_AND_CONDITIONS,
    component: TermsAndConditions,
  },
  {
    path: '/profile',
    name: RouteNames.PROFILE_WITHOUT_NOTIFICATION,
    component: Profile,
    props: {
      showNotification: false,
    },
  },
  {
    path: '/profile',
    name: RouteNames.PROFILE_WITH_NOTIFICATION,
    component: Profile,
    props: {
      showNotification: true,
    },
  },
  {
    path: '/profile/edit',
    name: RouteNames.EDIT_PROFILE,
    component: UpdateProfile,
  },
  {
    path: '/verification/profile',
    name: RouteNames.VERIFICATION_PROFILE,
    component: Verification,
  },
  {
    path: '/verification/practice',
    name: RouteNames.VERIFICATION_PRACTICE,
    component: Verification,
    props: {
      startStep: STEPS.practice,
    },
  },
  {
    path: '/verification/picture',
    name: RouteNames.VERIFICATION_PICTURE,
    component: Verification,
    props: {
      startStep: STEPS.profilePicture,
    },
  },
  {
    path: '/verification/id',
    name: RouteNames.VERIFICATION_ID,
    component: Verification,
    props: {
      startStep: STEPS.id,
    },
  },
  {
    path: '/verification/hpcsa',
    name: RouteNames.VERIFICATION_HPCSA,
    component: Verification,
    props: {
      startStep: STEPS.hpcsa,
    },
  },
  {
    path: '/logbox-practice',
    name: RouteNames.LOGBOX_PRACTICE,
    beforeEnter() {
      window.open(`https://${LOGBOX_PRACTICE_URL}`, '_blank')
    },
  },
  {
    path: '/logbox-academic',
    name: RouteNames.LOGBOX_ACADEMIC,
    beforeEnter() {
      window.open(`https://${LOGBOX_ACADEMIC_URL}`, '_blank')
    },
  },
  {
    path: '/logbox-sign-up',
    name: RouteNames.LOGBOX_SIGN_UP,
    beforeEnter() {
      window.open('https://logbox.co.za/#/signup', '_blank')
    },
  },
]

export default routes
