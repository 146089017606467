















import { Component, Prop, Vue } from 'vue-property-decorator'
import ProviderBlurb from '@/components/provider/ProviderBlurb.vue'
import ProviderOfferings from '@/components/provider/ProviderOfferings.vue'
import ProviderModel from '@/models/provider/provider-model'

@Component({
  components: {
    ProviderBlurb,
    ProviderOfferings,
  },
})
export default class Provider extends Vue {
  @Prop() provider!: ProviderModel

  @Prop() categoryName!: string

  @Prop() categoryId!: number
}
