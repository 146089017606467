import PracticeTypeModel from '@/models/needscalculator/practice-type-model'
import GradeModel from '@/models/needscalculator/grade-model'
import SpecialityModel from '@/models/needscalculator/speciality-model'
import TagModel from '@//models/offering/tags/tag-model'

export default class NeedsCalculatorFormModel {
  termsAndConditions = false

  id!: number

  firstName!: string

  lastName!: string

  emailAddress!: string

  age!: number

  practiceType!: PracticeTypeModel

  grade!: GradeModel

  speciality!: SpecialityModel

  tags!: TagModel[]
}
