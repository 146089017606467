var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"slot":"title"},slot:"title"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"text--title-responsive mt-5\n             text--colour-primary\n             word-break-full\n             text--align-center"},[_vm._v(" Personal Information ")])])],1),_c('v-form',{ref:"userBasicDetails",attrs:{"slot":"body"},slot:"body"},[_c('v-row',[_c('v-col',{staticClass:"form--body"},[_c('select-field',{staticClass:"shorter__input",attrs:{"label":"Title","dark":"","items":_vm.TITLES,"rules":[_vm.required('Confirming your title')]},model:{value:(_vm.userProfile.title),callback:function ($$v) {_vm.$set(_vm.userProfile, "title", $$v)},expression:"userProfile.title"}}),_c('text-field',{staticClass:"shorter__input",attrs:{"dark":"","required":"","label":"First Name","rules":[
            _vm.required('Confirming your first name')
          ]},model:{value:(_vm.userProfile.firstName),callback:function ($$v) {_vm.$set(_vm.userProfile, "firstName", $$v)},expression:"userProfile.firstName"}}),_c('text-field',{staticClass:"shorter__input",attrs:{"dark":"","required":"","label":"Last Name","rules":[
            _vm.required('Confirming your last name')
          ]},model:{value:(_vm.userProfile.lastName),callback:function ($$v) {_vm.$set(_vm.userProfile, "lastName", $$v)},expression:"userProfile.lastName"}}),_c('contact-number-field',{staticClass:"shorter__input",attrs:{"dark":"","required":"","label":"Mobile Number","rules":[
            _vm.required('Confirming your phone number')
          ]},model:{value:(_vm.userProfile.mobileNumber),callback:function ($$v) {_vm.$set(_vm.userProfile, "mobileNumber", $$v)},expression:"userProfile.mobileNumber"}}),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"4","lg":"5"}},[_c('v-btn',{class:{
                'passportButton': true,
                'passportButton--state-active': _vm.userProfile.isId,
                'passportButton--state-inactive': !_vm.userProfile.isId
              },on:{"click":function($event){_vm.userProfile.isId = true}}},[_vm._v(" ID Number ")]),_c('v-btn',{class:{
                'passportButton': true,
                'passportButton--state-active': !_vm.userProfile.isId,
                'passportButton--state-inactive': _vm.userProfile.isId
              },on:{"click":function($event){_vm.userProfile.isId = false}}},[_vm._v(" Passport ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"7","md":"8","lg":"7"}},[_c('text-field',{staticClass:"idVerification",attrs:{"dark":"","required":"","rules":[
                _vm.required(("Confirming your " + (_vm.userProfile.isId ? 'ID' : 'Passport') + " number")),
                _vm.userProfile.isId? _vm.validateRSAID():
                _vm.minLength('Passport Number', 3) ]},model:{value:(_vm.userProfile.idPassport),callback:function ($$v) {_vm.$set(_vm.userProfile, "idPassport", $$v)},expression:"userProfile.idPassport"}})],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"button--size-large button--light",attrs:{"to":{name:'PasswordManagement'}}},[_vm._v(" Change Password ")])],1),_c('v-row',{staticClass:"mt-10",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-btn',{staticClass:"button--size-large mr-0 mr-sm-5 button-center-align button--accent-inverse",on:{"click":function($event){return _vm.previous()}}},[_vm._v(" Cancel ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-btn',{staticClass:"button--size-large button--light",on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }