


















































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ProfileModel from '@/models/profile/profile-model'
import SupportEmail from '@/components/general/SupportEmail.vue'
import IdentityService from '@/services/api/identity-service'

const ProfileModule = namespace('profileStore')

@Component({
  components: { SupportEmail },
})
export default class VerificationEmailSent extends Vue {
  @ProfileModule.State('userProfile')
  userProfile!: ProfileModel

  @ProfileModule.State('loggedIn')
  loggedIn!: boolean

  responseIsError = false

  shouldDisplayResendResponse = false

  loading = false

  async resendEmail() {
    this.loading = true
    try {
      this.responseIsError = false
      if (this.userProfile.isDefault()) {
        this.responseIsError = true
      } else {
        await IdentityService.postVerifyEmailAddress()
        this.responseIsError = false
      }
    } catch (e) {
      this.responseIsError = true
    } finally {
      this.shouldDisplayResendResponse = true
      this.loading = false
    }
  }
}
