const base64EncodeImage = function base64EncodeImage(image: ArrayBuffer) {
  if (image) {
    return `data:image/jpeg;base64,${btoa(
      new Uint8Array(image)
        .reduce((data, byte) => data + String.fromCharCode(byte), ''),
    )}`
  }
  return ''
}

export default base64EncodeImage
