import axios from 'axios'
import { VuexModule, Module, MutationAction } from 'vuex-module-decorators'
import CategoryModel from '@/models/offering/category-model'

@Module({
  namespaced: true,
  name: 'CategoryStore',
})
export default class CategoryStore extends VuexModule {
  categories: CategoryModel[] = []

  @MutationAction({ mutate: ['categories'] })
  async updateCategories() {
    const response = await axios.get('/api/categories')
    return { categories: response.data as CategoryModel[] }
  }
}
