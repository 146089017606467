import { Component, Vue } from 'vue-property-decorator'
import CONSTANTS from '@/constants/constants'

@Component
export default class EnvironmentUrlMixin extends Vue {
  get environmentUrl() {
    let sizeDirectory = 'normal-device'
    if (this.$vuetify.breakpoint.mobile) {
      sizeDirectory = 'smaller-device'
    }
    return `${this.baseUrl}/${sizeDirectory}`
  }

  get baseUrl() {
    let ret = `${window.location.origin}`
    if (ret.includes('localhost')) {
      ret = 'https://qa.circamed.co.za'
    }
    return `${ret}/images`
  }

  get staticLogosUrl() {
    return this.baseUrl + CONSTANTS.IMAGES.STATIC_LOGOS
  }

  get staticLandingUrl() {
    return this.environmentUrl + CONSTANTS.IMAGES.STATIC_LANDING
  }

  get staticIllustrationsUrl() {
    return this.environmentUrl + CONSTANTS.IMAGES.STATIC_ILLUSTRATIONS
  }

  get customIconsUrl() {
    return `${this.baseUrl}/custom-icons`
  }
}
