





















































































































import { Component, Ref } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { VFormType } from '@/types/v-form-type'
import validationMixin from '@/mixins/validation-mixin'
import PlatformMotive from '@/components/provider/PlatformMotive.vue'
import DoubleFormCard from '@/components/form/DoubleFormCard.vue'
import IconWithText from '@/components/form/IconWithText.vue'
import TextField from '@/components/form/TextField.vue'
import PasswordField from '@/components/form/PasswordField.vue'
import LoginFormModel from '@/models/form/login-form-model'
import PlatformModel from '@/models/provider/platform-model'
import RouteNames from '@/router/route-names'
import LoginSignupDialog from '@/components/dialog/LoginSignupDialog.vue'

const ProviderModule = namespace('providerStore')
const LoadingIndicatorModule = namespace('loadingIndicatorStore')
const ProfileModule = namespace('profileStore')

@Component({
  components: {
    PlatformMotive,
    IconWithText,
    TextField,
    PasswordField,
    DoubleFormCard,
    LoginSignupDialog,
  },
})
export default class Login extends mixins(validationMixin) {
  @Ref('loginFormRef') loginFormRef!: VFormType

  @ProfileModule.Action('updateClientContext')
  updateClientContext!: Function

  @ProfileModule.Action('followClientContextRedirect')
  followClientContextRedirect!: Function

  @LoadingIndicatorModule.Mutation('showLoadingIndicator')
  showLoadingIndicator!: Function

  @ProviderModule.State('platform')
  platform!: PlatformModel

  @ProviderModule.Action('getCircamedPlatform')
  getPlatform!: Function

  @ProfileModule.Action('loginRequest')
  loginRequest!: Function

  @ProfileModule.State('loggedIn')
  loggedIn!: boolean

  private loginForm = new LoginFormModel()

  private displayDialog = false

  private buttonText = ''

  private statusDialogHeading = ''

  private statusDialogText = ''

  private loading = false

  get form(): VFormType {
    return this.$refs.loginFormRef as VFormType
  }

  async created() {
    await this.updateClientContext()
    if (this.loggedIn) {
      await this.followClientContextRedirect()
      await this.$router.replace({
        name: RouteNames.PROFILE_WITHOUT_NOTIFICATION,
      })
    } else {
      this.showLoadingIndicator(true)
      await this.getPlatform()
      this.showLoadingIndicator(false)
    }
  }

  async login() {
    if (this.form.validate()) {
      this.loading = true
      try {
        await this.loginRequest(this.loginForm)
        this.displayDialog = false
        if (this.loggedIn) {
          await this.followClientContextRedirect()
          await this.$router.push({ name: RouteNames.PROFILE_WITHOUT_NOTIFICATION })
        }
      } catch (e) {
        this.displayDialog = true
        if (e.response.status === 401) {
          this.statusDialogHeading = 'Your username and password do not match'
          this.statusDialogText = 'Please make sure you have an account and your details are correct, and then try again'
          this.buttonText = 'Ok'
        } else {
          this.statusDialogHeading = 'Oops something went wrong on our side'
          this.statusDialogText = 'Please try again later'
          this.buttonText = 'Ok'
        }
      }
    }
    this.loading = false
  }
}
