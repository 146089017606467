var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'double--form-card': true,
    'form--card-mobile': _vm.isSmallerDevice,
    'form--card-desktop': !_vm.isSmallerDevice
  }},[_c('v-row',{staticClass:"form--shadow",attrs:{"justify":"center"}},[_c('v-col',{class:{
        'form--left': true,
        'card--color-light': true,
        'form--card-base': true,
        'form--left-mobile': _vm.isSmallerDevice,
        'form--left-desktop': !_vm.isSmallerDevice,
      },attrs:{"cols":"12","md":"7"}},[(this.$slots['leftTitle'])?_c('div',{staticClass:"form--title text--title-responsive card--color-light"},[_vm._t("leftTitle")],2):_vm._e(),_c('div',{staticClass:"text--body-responsive"},[_vm._t("leftBody")],2)]),_c('v-col',{class:{
        'form--right':true,
        'card--color-dark': !_vm.colorGrayAlternative,
        'card--color-gray-alternative': _vm.colorGrayAlternative,
        'form--card-base': true,
        'form--right-mobile': _vm.isSmallerDevice,
        'form--right-desktop': !_vm.isSmallerDevice,
      },attrs:{"cols":"12","md":"5"}},[(this.$slots['rightTitle'])?_c('div',{staticClass:"form--title text--title-responsive"},[_vm._t("rightTitle")],2):_vm._e(),_c('div',{staticClass:"text--body-responsive"},[_vm._t("rightBody")],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }