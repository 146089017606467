































































































































import { Component, Ref } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import validationMixin from '@/mixins/validation-mixin'
import DoubleFormCard from '@/components/form/DoubleFormCard.vue'
import IconWithText from '@/components/form/IconWithText.vue'
import TextField from '@/components/form/TextField.vue'
import PasswordField from '@/components/form/PasswordField.vue'
import FormCard from '@/components/form/FormCard.vue'
import PasswordRequirements from '@/components/form/PasswordRequirements.vue'
import PasswordManagementFormModel from '@/models/form/password-management-form-model'
import { VFormType } from '@/types/v-form-type'
import RouteNames from '@/router/route-names'
import AuthService from '@/services/api/auth-service'
import { namespace } from 'vuex-class'

const ProfileModule = namespace('profileStore')

@Component({
  components: {
    FormCard,
    PasswordRequirements,
    IconWithText,
    TextField,
    PasswordField,
    DoubleFormCard,
  },
})
export default class PasswordManagement extends mixins(validationMixin) {
  @Ref('passwordManagementFormRef') passwordManagementFormRef!: VFormType

  @ProfileModule.Action('authRequest')
  authRequest!: Function

  @ProfileModule.State('loggedIn')
  private loggedIn!: boolean

  private passwordManagementForm = new PasswordManagementFormModel()

  private busy = false

  private error = false

  private success = false

  get form(): VFormType {
    return this.$refs.passwordManagementFormRef as VFormType
  }

  equalsPassword(v: string) {
    return v === this.passwordManagementForm.newPassword || 'Must match password.'
  }

  cancel() {
    this.form.reset()
    this.$router.back()
  }

  back() {
    this.$router.back()
  }

  goToLogin() {
    this.$router.push({ name: RouteNames.LOGIN })
  }

  async changePassword() {
    if (this.form.validate()) {
      this.error = false
      this.busy = true
      try {
        await AuthService.postChangePassword(this.passwordManagementForm)
        this.success = true
        this.form.reset()
      } catch (e) {
        this.error = true
      } finally {
        this.busy = false
      }
    }
  }
}
