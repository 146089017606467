












































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class IconWithText extends Vue {
  @Prop({ type: Boolean, default: false }) dark!: boolean

  @Prop({ type: Boolean, default: false }) smallerMargin!: boolean

  @Prop() icon!: string

  @Prop({ default: '40px' }) iconSize!: string

  @Prop({ type: Boolean, default: true }) isTitle!: boolean

  @Prop({ type: Boolean, default: false }) isSubtitle!: boolean

  @Prop() text!: string
}
