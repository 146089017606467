import OfferingModel from '@/models/offering/offering-model'
import ProfileModel from '@/models/profile/profile-model'

export default class ContactUsFormModel {
  firstName = ''

  lastName = ''

  emailAddress = ''

  message = ''

  termsAndConditions = false

  recommendedOfferings: OfferingModel[] = []

  constructor(profile?: ProfileModel) {
    if (profile) {
      this.firstName = profile.firstName
      this.lastName = profile.lastName
      this.emailAddress = profile.emailAddress
    }
  }
}
