




















































import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import screenSizeMixin from '@/mixins/screen-size-mixin'
import { namespace } from 'vuex-class'
import ProfileModel from '@/models/profile/profile-model'

const ProfileModule = namespace('profileStore')

@Component
export default class TabsMenu extends mixins(screenSizeMixin) {
  @ProfileModule.State('loggedIn')
  loggedIn !: boolean

  @ProfileModule.State('userProfile')
  userProfile !: ProfileModel

  get initials() {
    if (this.userProfile.firstName === '') {
      return ''
    }
    return `${this.userProfile.firstName.charAt(0).toUpperCase()}.${this.userProfile.lastName.charAt(0).toUpperCase()}`
  }
}
