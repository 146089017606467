




























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TsAndCsCheckbox extends Vue {
  @Prop() rules!: Array<Function>

  @Prop() readonly checked !: boolean

  @Prop({ type: Boolean, default: false }) readonly validationErrorAnimation !: boolean
}
