






















































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { VFormType } from '@/types/v-form-type'
import validationMixin from '@/mixins/validation-mixin'
import PlatformMotive from '@/components/provider/PlatformMotive.vue'
import DoubleFormCard from '@/components/form/DoubleFormCard.vue'
import IconWithText from '@/components/form/IconWithText.vue'
import TextField from '@/components/form/TextField.vue'
import PasswordField from '@/components/form/PasswordField.vue'
import TextArea from '@/components/form/TextArea.vue'
import FormCard from '@/components/form/FormCard.vue'
import FormResponseDialog from '@/components/dialog/FormResponseDialog.vue'
import TsAndCsCheckbox from '@/components/lead/TsAndCsCheckbox.vue'
import OfferingModel from '@/models/offering/offering-model'
import PlatformModel from '@/models/provider/platform-model'
import ContactUsFormModel from '@/models/form/contact-us-form-model'
import ProfileModel from '@/models/profile/profile-model'

const ProviderModule = namespace('providerStore')
const ContactUsModule = namespace('contactUsStore')
const ProfileModule = namespace('profileStore')

@Component({
  components: {
    PlatformMotive,
    IconWithText,
    TextField,
    PasswordField,
    DoubleFormCard,
    TextArea,
    FormResponseDialog,
    FormCard,
    TsAndCsCheckbox,
  },
})
export default class ContactUs extends mixins(validationMixin) {
  @ProfileModule.State('loggedIn')
  loggedIn !: boolean

  @ProfileModule.State('userProfile')
  userProfile !: ProfileModel

  @ProviderModule.State('platform')
  platform!: PlatformModel

  @ProviderModule.Action('getCircamedPlatform')
  getPlatform!: Function

  @ContactUsModule.State('response')
  contactUsResponse!: string

  @ContactUsModule.Action('submit')
  submitContactUsForm!: Function

  @Prop() recommendedOfferings!: OfferingModel[]

  private contactForm = new ContactUsFormModel()

  private displayDialog = false

  private shouldClearForm = false

  private loading = false

  private statusDialogText = ''

  private statusDialogHeading = ''

  async created() {
    this.getPlatform()
    if (this.loggedIn) {
      this.contactForm = new ContactUsFormModel(this.userProfile)
    }
  }

  get form(): VFormType {
    return this.$refs.contactUsFormRef as VFormType
  }

  clearForm() {
    if (this.shouldClearForm) {
      this.form.reset()
    }
  }

  async sendEmail() {
    if (this.form.validate()) {
      try {
        this.displayDialog = true
        this.loading = true
        if (this.recommendedOfferings) {
          this.contactForm.recommendedOfferings = this.recommendedOfferings
        }
        await this.submitContactUsForm(this.contactForm)
        this.statusDialogHeading = 'Your query has been sent successfully!'
        this.statusDialogText = ' Our team will get back to you shortly.'
        this.shouldClearForm = true
      } catch (error) {
        this.statusDialogHeading = 'Your enquiry was unable to be forwarded to our team.'
        this.statusDialogText = 'Please try again later or email us directly at: '
          + '<a class="form--link-color" href="mailto:contact@logbox.co.za">'
          + 'contact@logbox.co.za'
          + '</a>'
        this.shouldClearForm = false
      }

      this.loading = false
    }
  }

  @Watch('userProfile', { deep: true })
  updateFormModel() {
    if (this.loggedIn) {
      this.contactForm = new ContactUsFormModel(this.userProfile)
    } else {
      this.contactForm = new ContactUsFormModel()
    }
  }
}
