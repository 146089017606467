































































































import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { VFormType } from '@/types/v-form-type'
import FormCard from '@/components/form/FormCard.vue'
import IconWithText from '@/components/form/IconWithText.vue'
import TextField from '@/components/form/TextField.vue'
import ContactNumberField from '@/components/form/ContactNumberField.vue'
import SelectField from '@/components/form/SelectField.vue'
import PracticeTypeModel from '@/models/needscalculator/practice-type-model'
import verificationStepMixin from '@/mixins/verification-step-mixin'
import validationMixin from '../../mixins/validation-mixin'

const NeedsCalculatorFormModule = namespace('needsCalculatorFormStore')
const ProfileModule = namespace('profileStore')

@Component({
  components: {
    FormCard,
    IconWithText,
    TextField,
    ContactNumberField,
    SelectField,
  },
})
export default class VerificationPractice extends mixins(
  validationMixin,
  verificationStepMixin,
) {
  @NeedsCalculatorFormModule.State('practiceTypes')
  practiceTypes!: PracticeTypeModel[]

  @NeedsCalculatorFormModule.Action('updatePracticeTypes')
  updatePracticeTypes!: Function

  @ProfileModule.Action('updateProfileRequest')
  updateProfileRequest!: Function

  @ProfileModule.State('disciplines')
  disciplines!: string[]

  @ProfileModule.Action('updateDisciplines')
  updateDisciplines!: Function

  get form() {
    return this.$refs.practiceInfoForm as VFormType
  }

  async created() {
    this.updatePracticeTypes()
    this.updateDisciplines()
  }

  async nextPage() {
    if (this.form.validate()) {
      this.updateStore()
      await this.updateProfileRequest()
      this.$emit('next')
    }
  }

  previousPage() {
    this.$emit('previous')
  }
}
