




















































































import {
  Component, Prop, Ref, Watch,
} from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { TITLES } from '@/services/title-service'
import FormCard from '@/components/form/FormCard.vue'
import TextField from '@/components/form/TextField.vue'
import SelectField from '@/components/form/SelectField.vue'
import ContactNumberField from '@/components/form/ContactNumberField.vue'
import validationMixin from '@/mixins/validation-mixin'
import LeadGenerationFormModel from '@/models/lead/lead-generation-form-model'
import OfferingModel from '@/models/offering/offering-model'
import ProviderModel from '@/models/provider/provider-model'
import LeadModel from '@/models/lead/lead-model'
import LeadActionModel from '@/models/lead/lead-action-model'
import { VFormType } from '@/types/v-form-type'
import TsAndCsCheckbox from '@/components/lead/TsAndCsCheckbox.vue'
import FormResponseDialog from '@/components/dialog/FormResponseDialog.vue'
import ProfileModel from '@/models/profile/profile-model'

const ErrorModule = namespace('errorStore')
const LeadModule = namespace('leadStore')
const ProfileModule = namespace('profileStore')

@Component({
  components: {
    TsAndCsCheckbox,
    FormResponseDialog,
    ContactNumberField,
    FormCard,
    TextField,
    SelectField,
  },

})
export default class LeadGenerationForm extends mixins(validationMixin) {
  @ProfileModule.State('loggedIn')
  loggedIn !: boolean

  @ProfileModule.State('userProfile')
  userProfile !: ProfileModel

  @LeadModule.Action('submit')
  submitForm!: Function

  @LeadModule.State('response')
  response!: never

  @ErrorModule.Mutation('updateError')
  setError!: Function

  @LeadModule.State('path')
  leadPath!: LeadActionModel[]

  @LeadModule.Mutation('addToPath')
  addToPath!: Function

  @LeadModule.State('leadTrack')
  leadTrack!: LeadModel

  @Prop() offering!: OfferingModel

  @Prop() provider!: ProviderModel

  @Ref('leadGenerationFormRef') leadGenerationFormRef!: VFormType

  private leadGenerationForm: LeadGenerationFormModel = new LeadGenerationFormModel()

  private display = false

  private loading = false

  private statusDialogText = ''

  private statusDialogHeading = ''

  private shouldClearForm = false

  TITLES = TITLES

  created() {
    if (this.loggedIn) {
      this.leadGenerationForm = new LeadGenerationFormModel(this.userProfile)
    }
  }

  get form(): VFormType {
    return this.$refs.leadGenerationFormRef as VFormType
  }

  clearForm() {
    if (this.shouldClearForm) {
      this.form.reset()
      this.leadGenerationForm.contactNumber = ''
    }
  }

  async submitDetails() {
    if (this.form.validate()) {
      this.leadGenerationForm.offering = this.offering
      this.leadGenerationForm.provider = this.provider

      const lead = new LeadModel()
      lead.path = this.leadPath

      if (this.loggedIn) {
        lead.userName = this.userProfile.username
      } else {
        lead.userName = 'unregistered'
      }

      this.leadGenerationForm.lead = lead

      try {
        this.display = true
        this.loading = true
        await this.submitForm(this.leadGenerationForm)
        this.statusDialogHeading = 'Your request has been submitted'
        this.statusDialogText = 'A broker will be in contact shortly.'
        this.shouldClearForm = true
      } catch (error) {
        this.statusDialogHeading = 'Unfortunately we were unable to process your request'
        this.statusDialogText = 'Please try again later.'
        this.shouldClearForm = false
      }
      this.loading = false
    }
  }

  @Watch('userProfile', { deep: true })
  updateFormModel() {
    if (this.loggedIn) {
      this.leadGenerationForm = new LeadGenerationFormModel(this.userProfile)
    } else {
      this.leadGenerationForm = new LeadGenerationFormModel()
    }
  }
}
