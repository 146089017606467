

























































import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import screenSizeMixin from '@/mixins/screen-size-mixin'

@Component
export default class DoubleFormCard extends mixins(screenSizeMixin) {
  @Prop({ default: false })
  colorGrayAlternative!: boolean
}
