import {
  Module, Mutation, MutationAction, VuexModule,
} from 'vuex-module-decorators'
import LeadModel from '@/models/lead/lead-model'
import LeadActionModel from '@/models/lead/lead-action-model'
import LeadGenerationFormModel from '@/models/lead/lead-generation-form-model'
import axios from 'axios'

@Module({
  namespaced: true,
  name: 'Lead',
})
export default class LeadStore extends VuexModule {
  response = ''

  path: LeadActionModel[] = []

  leadTrack: LeadModel = new LeadModel()

  @Mutation
  addToPath(page: LeadActionModel) {
    this.path.push(page)
  }

  @MutationAction({ mutate: ['response'] })
  async submit(leadGenerationForm: LeadGenerationFormModel) {
    const response = await axios.post('/api/leads', leadGenerationForm)
    return { response: response.data }
  }
}
