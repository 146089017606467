






















































































































































































































































































































































































































































import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { FEATURE_FLAGS, isFeatureEnabled } from '@/services/feature-flag-service'
import ProviderBlurb from '@/components/provider/ProviderBlurb.vue'
import ConnectBrokerCard from '@/components/lead/ConnectBrokerCard.vue'
import PersonalNeedsBasedCalculator from '@/components/needscalculator/PersonalNeedsBasedCalculator.vue'
import PlatformModel from '@/models/provider/platform-model'
import FeatureFlagModel from '@/models/feature/feature-flag-model'
import ProviderModel from '@/models/provider/provider-model'
import CategoryModel from '@/models/offering/category-model'
import OfferingModel from '@/models/offering/offering-model'
import screenSizeMixin from '@/mixins/screen-size-mixin'
import SupportEmail from '@/components/general/SupportEmail.vue'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'

const CategoryModule = namespace('categoryStore')
const ProviderModule = namespace('providerStore')
const OfferingModule = namespace('offeringStore')
const FeatureFlagModule = namespace('featureFlagStore')
const LoadingIndicatorModule = namespace('loadingIndicatorStore')

@Component({
  components: {
    ProviderBlurb,
    ConnectBrokerCard,
    PersonalNeedsBasedCalculator,
    SupportEmail,
  },
})
export default class Home extends mixins(screenSizeMixin, EnvironmentUrlMixin) {
  @CategoryModule.State('categories')
  categories!: CategoryModel[]

  @OfferingModule.State('offeringDescriptions')
  offeringDescriptions!: OfferingModel[]

  @ProviderModule.State('platform')
  platform!: PlatformModel

  @ProviderModule.State('provider')
  fetchedProvider!: ProviderModel

  @FeatureFlagModule.State('featureFlags')
  featureFlags!: FeatureFlagModel[]

  @ProviderModule.Action('getCircamedPlatform')
  getPlatform!: Function

  @ProviderModule.Action('updateProviderByName')
  updateProviderByName!: Function

  @OfferingModule.Action('loadOfferingDescriptions')
  loadOfferingDescriptions!: Function

  @LoadingIndicatorModule.Mutation('showLoadingIndicator')
  showLoadingIndicator!: Function

  private displayCalculator = false

  private ppsInfo: ProviderModel = new ProviderModel()

  private ppsOfferings: OfferingModel[] = []

  private cmsaInfo: ProviderModel = new ProviderModel()

  private cmsaOfferings: OfferingModel[] = []

  private logboxInfo: ProviderModel = new ProviderModel()

  get needsCalculatorFeatureFlag() {
    return isFeatureEnabled(this.featureFlags, FEATURE_FLAGS.PERSONAL_NEEDS_CALCULATOR)
  }

  get StaticContent(): ProviderModel {
    return {
      brokerDescription: '',
      id: 0,
      name: 'CircaMed',
      title: 'CircaMed Practice Starter for Registrars',
      category: new CategoryModel(),
      offeringIds: [],
      shortDescription: 'A bundled offering giving you a great start. Any practice is not successful without the ability to: ',
      longDescription: '',
      features: [
        {
          id: 0,
          icon: '$vuetify.bill',
          title: 'Bill Your Clients',
          description: '',
        },
        {
          id: 1,
          icon: '$vuetify.umbrella',
          title: 'Cover you against various medical, business and personal insurance events',
          description: '',
        },
        {
          id: 2,
          icon: '$vuetify.digitise',
          title: 'Digitise your practice using the latest technology ',
          description: '',
        },
      ],
      benefits: [],
      hasIllustration: true,
      illustrationPath: 'flower-all.svg',
      logo: '',
    }
  }

  async created() {
    this.showLoadingIndicator(true)
    await this.getPlatform()
    await this.getPPSInfo()
    await this.getLogBoxInfo()
    await this.getCMSAInfo()
    this.findCategories()
    this.showLoadingIndicator(false)
  }

  async getPPSInfo() {
    await this.updateProviderByName('PPS')
    this.ppsInfo = this.fetchedProvider

    await this.loadOfferingDescriptions(this.ppsInfo.offeringIds.toString())
    this.ppsOfferings = this.offeringDescriptions
  }

  async getCMSAInfo() {
    try {
      await this.updateProviderByName('CMSA')
      this.cmsaInfo = this.fetchedProvider

      await this.loadOfferingDescriptions(this.cmsaInfo.offeringIds.toString())
      this.cmsaOfferings = this.filterOfferingByName(this.offeringDescriptions, 'Purchase Insignia')
    } catch (e) {
      // DO NOTHING THE PROVIDER HAS BEEN DEACTIVATED
    }
  }

  async getLogBoxInfo() {
    await this.updateProviderByName('LogBox')
    this.logboxInfo = this.fetchedProvider
  }

  findCategories() {
    let foundInsurance = false
    let foundSoftware = false
    for (let i = 0; i < this.categories.length; i += 1) {
      if (this.categories[i].name === 'Insurance') {
        this.ppsInfo.category = this.categories[i]
        foundInsurance = true
      }
      if (this.categories[i].name === 'Software') {
        this.logboxInfo.category = this.categories[i]
        foundSoftware = true
      }
      if (foundInsurance && foundSoftware) {
        break
      }
    }
  }

  findOfferingByName(offerings: OfferingModel[], name: string) {
    return offerings.find((offering) => offering.name?.toLowerCase() === name.toLowerCase())
  }

  filterOfferingByName(offerings: OfferingModel[], name: string) {
    return offerings.filter((offering) => offering.name?.toLowerCase() !== name.toLowerCase())
  }
}
