import ProfileModel from '@/models/profile/profile-model'

export default class UpdateProfileRequestModel {
  title: string | null = ''

  firstName = ''

  lastName = ''

  mobileNumber = ''

  idNumber: string | undefined = undefined

  passportNumber: string | undefined = undefined

  discipline = ''

  hpcsaNumber = ''

  practiceNumber = ''

  practicePhoneNumber = ''

  acknowledgedTermsAndConditions = false

  constructor(profile: ProfileModel) {
    this.title = profile.title
    this.firstName = profile.firstName
    this.lastName = profile.lastName
    this.mobileNumber = profile.mobileNumber
    this.idNumber = profile.isId ? profile.idPassport : undefined
    this.passportNumber = !profile.isId ? profile.idPassport : undefined
    this.discipline = profile.discipline
    this.hpcsaNumber = profile.hpcsaNumber
    this.practiceNumber = profile.practiceNumber
    this.practicePhoneNumber = profile.practicePhoneNumber
    this.acknowledgedTermsAndConditions = profile.acknowledgedTermsAndConditions
  }
}
