import FeatureModel from '@/models/provider/feature-model'

export default class PlatformModel {
  id = 0

  name = ''

  title = ''

  features: FeatureModel[] = []

  longDescription = ''

  shortDescription = ''

  hasPentagonDiagram = false

  logo = ''

  partnerLogos = ''
}
