


































































































import { Component, Prop } from 'vue-property-decorator'
import OfferingModel from '@/models/offering/offering-model'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'
import { mixins } from 'vue-class-component'

@Component
export default class BasicOfferingCard extends mixins(EnvironmentUrlMixin) {
  @Prop() readonly offering!: OfferingModel

  @Prop() readonly logo!: string

  @Prop() smallerImage!: boolean

  @Prop() categoryId!: string

  @Prop() categoryName!: string
}
