

















































import { Component, Prop, Vue } from 'vue-property-decorator'
import FeatureModel from '@/models/provider/feature-model'

@Component
export default class FeatureList extends Vue {
  @Prop() features!: FeatureModel[]

  @Prop() hasPentagonDiagram!: boolean

  @Prop({ default: 'dense' }) iconSize!: string
}
