






















































































































import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ProviderCategoryDiagram from '@/components/provider/ProviderCategoryDiagram.vue'
import FeatureList from '@/components/feature/FeatureList.vue'
import screenSizeMixin from '@/mixins/screen-size-mixin'
import browserTypeMixin from '@/mixins/browser-type-mixin'
import ProviderModel from '@/models/provider/provider-model'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'

@Component({
  components: {
    ProviderCategoryDiagram,
    FeatureList,
  },
})
export default class ProviderBlurb extends mixins(
  screenSizeMixin,
  browserTypeMixin,
  EnvironmentUrlMixin,
) {
  @Prop() provider!: ProviderModel

  @Prop({ type: Boolean }) hideBenefit!: boolean

  @Prop({ type: Boolean }) hasExtraContent!: boolean

  get colsForDescriptions() {
    if (this.provider.hasIllustration) {
      return 6
    }
    return 12
  }

  get colsForLongDescription() {
    if (this.provider.hasIllustration) {
      return 7
    }
    return 12
  }

  get featureIconSize() {
    if (this.$vuetify.breakpoint.smAndUp) return '1.8em'
    return '1.4em'
  }
}
