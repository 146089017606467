import ProfileModel from '@/models/profile/profile-model'
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import validationMixin from '@/mixins/validation-mixin'
import { namespace } from 'vuex-class'

const ProfileModule = namespace('profileStore')

@Component
export default class VerificationStepMixin extends mixins(validationMixin) {
  @ProfileModule.State('userProfile')
  userProfileStored!: ProfileModel

  @ProfileModule.State('loggedIn')
  loggedIn!: boolean

  @ProfileModule.Mutation('updateUserProfile')
  updateUserProfile!: Function

  @ProfileModule.Mutation('updateLoginState')
  updateLoginState!: Function

  @ProfileModule.Action('fetchProfile')
  fetchProfile!: Function

  userProfile: ProfileModel = new ProfileModel()

  async loadForm() {
    if (this.loggedIn && this.userProfileStored.firstName === '') {
      await this.fetchProfile()
    }
    this.userProfile = this.userProfileStored
  }

  async cancel() {
    await this.loadForm()
  }

  updateStore() {
    this.updateUserProfile(this.userProfile)
  }

  previousPage() {
    this.$emit('previous')
  }

  showErrors(errorMessage: string) {
    this.$emit('error', errorMessage)
  }

  async created() {
    await this.loadForm()
  }
}
