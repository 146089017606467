import { Component, Vue } from 'vue-property-decorator'
import IDValidationService from '@/services/rsa-id-validation-service'

@Component
export default class ValidationMixin extends Vue {
  required(name: string): Function {
    return (input: string | boolean) => !!input || `${name} is required.`
  }

  minLength(name: string, length: number): Function {
    return (input: string) => {
      if (!!input && input.length >= length) {
        return true
      }
      return `${name} must be at least ${length} characters.`
    }
  }

  maxLength(name: string, length: number): Function {
    return (input: string) => {
      if (!!input && input.length <= length) {
        return true
      }
      return `${name} must be at most ${length} characters.`
    }
  }

  emailFormat(): Function {
    return (input: string) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(input)
      || 'Email address is in an incorrect format.'
  }

  ageFormat(): Function {
    return (input: string) => /^(1[89]|[2-9][0-9])$/.test(input) || 'Age must be between 18 and 99.'
  }

  passwordFormat(): Function {
    return (input: string) => {
      if (!/[A-Z]/.test(input)) {
        return 'Password must contain an uppercase letter.'
      }
      if (!/[a-z]/.test(input)) {
        return 'Password must contain a lowercase letter.'
      }
      if (!/[0-9]/.test(input)) {
        return 'Password must contain a number.'
      }
      if (!/[^a-zA-Z0-9]/.test(input)) {
        return 'Password must contain a special character.'
      }
      return this.minLength('Password', 8)(input)
    }
  }

  validateRSAID(): Function {
    return (idNumber: string) => IDValidationService.validateID(idNumber) || 'Invalid ID Number.'
  }

  checkBoxExpected(name: string): Function {
    return (input: string | boolean) => !!input || `${name} is required.`
  }
}
