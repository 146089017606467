export default {
  validateLength(idNumber: string): boolean {
    return idNumber != null && idNumber.length >= 13
  },

  validateDateOfBirth(idNumber: string): boolean {
    const yearPart = this.getBirthYearPart(idNumber)
    const idYear = parseInt(yearPart, 10)
    const idMonth = parseInt(this.getBirthMonthPart(idNumber), 10) - 1
    const idDate = parseInt(this.getBirthDatePart(idNumber), 10)

    const date: Date = new Date()
    const century = date.getFullYear().toString().substring(0, 2)
    const year = parseInt(century.concat(yearPart), 10)

    date.setFullYear(year)
    date.setMonth(idMonth)
    date.setDate(idDate)

    const isValidYear = parseInt(date.getFullYear().toString().substring(2, 4), 10) === idYear

    return isValidYear && date.getMonth() === idMonth && date.getDate() === idDate;
  },

  getBirthYearPart(idNumber: string): string {
    return idNumber != null && idNumber.length >= 6 ? idNumber.substring(0, 2) : '';
  },

  getBirthMonthPart(idNumber: string): string {
    return idNumber != null && idNumber.length >= 6 ? idNumber.substring(2, 4) : '';
  },

  getBirthDatePart(idNumber: string): string {
    return idNumber != null && idNumber.length >= 6 ? idNumber.substring(4, 6) : '';
  },

  validateCitizenship(idNumber: string): boolean {
    const citizenship = parseInt(idNumber.substring(10, 11), 10)
    return citizenship <= 1
  },

  validateCheckDigit(idNumber: string): boolean {
    const checkDigit = parseInt(idNumber.substring(idNumber.length - 1), 10)

    return checkDigit === this.calculateCheckDigit(idNumber)
  },

  calculateCheckDigit(idNumber: string): number {
    const oddTotal: number = this.addOddPositionDigits(idNumber)
    const evenTotal: number = this.addEvenDigits(idNumber)
    const oddAndEvenTotal: number = oddTotal + evenTotal

    const secondDigit: number = parseInt(oddAndEvenTotal.toString().charAt(1), 10)
    let check = 10 - secondDigit

    if (check > 9) {
      check = parseInt((`${check}`).charAt(1), 10)
    }

    return check
  },

  getIdNumberPart(idNumber: string): string {
    return idNumber.substring(0, idNumber.length - 1)
  },

  addOddPositionDigits(idNumber: string): number {
    let oddTotal = 0

    const idNumberPart: string = this.getIdNumberPart(idNumber)
    try {
      for (let i = 0; i < idNumberPart.length; i += 2) {
        oddTotal += parseInt(idNumberPart.substring(i, i + 1), 10)
      }
    } catch (e) {
      return 0
    }

    return oddTotal
  },

  multiplyEvenPositionDigits(idNumber: string): number {
    const idNumberPart: string = this.getIdNumberPart(idNumber)

    let evenNumbers = ''
    for (let i = 1; i < idNumberPart.length; i += 2) {
      evenNumbers = evenNumbers.concat(idNumberPart.substring(i, i + 1))
    }

    return parseInt(evenNumbers.toString(), 10) * 2
  },

  addEvenDigits(idNumber: string): number {
    let evenTotal = 0

    const evenNumber: string = this.multiplyEvenPositionDigits(idNumber).toString()
    for (let i = 0; i < evenNumber.length; i += 1) {
      evenTotal += parseInt(evenNumber.substring(i, i + 1), 10)
    }

    return evenTotal
  },

  validateID(idNumber: string): boolean {
    return this.validateLength(idNumber)
      && this.validateDateOfBirth(idNumber)
      && this.validateCitizenship(idNumber)
      && this.validateCheckDigit(idNumber)
  },
}
