import TagModel from '@/models/offering/tags/tag-model'

export default class RangeTagModel extends TagModel {
  value = -1

  constructor(val: number, name: string) {
    super('range_tag', name);
    this.value = val
  }
}
