import OfferingModel from '@/models/offering/offering-model'

export default class OfferingPaginationModel {
  keyName = ''

  page = 0

  totalPages = 0

  batchSize = 0

  ids: number[] = []

  offerings: OfferingModel[] = []

  constructor(keyName: string, page: number, totalPages: number, batchSize: number,
    ids: number[], offerings: OfferingModel[]) {
    this.keyName = keyName
    this.page = page
    this.totalPages = totalPages
    this.batchSize = batchSize
    this.ids = ids
    this.offerings = offerings
  }
}
