







































































import { Component, Ref } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { VFormType } from '@/types/v-form-type'
import validationMixin from '@/mixins/validation-mixin'
import NewPasswordFormModel from '@/models/form/new-password-form-model'
import TextField from '@/components/form/TextField.vue'
import IconWithText from '@/components/form/IconWithText.vue'
import FormCard from '@/components/form/FormCard.vue'
import RouteNames from '@/router/route-names'
import PasswordRequirements from '@/components/form/PasswordRequirements.vue'
import PasswordField from '@/components/form/PasswordField.vue'
import AuthService from '@/services/api/auth-service'

@Component({
  components: {
    PasswordField,
    PasswordRequirements,
    FormCard,
    IconWithText,
    TextField,
  },
})
export default class NewPassword extends mixins(validationMixin) {
  @Ref('newPasswordFormRef') newPasswordFormRef!: VFormType

  private busy = false

  private expired = false

  private success = false

  private newPasswordFormModel = new NewPasswordFormModel()

  get form(): VFormType {
    return this.$refs.newPasswordFormRef as VFormType
  }

  get titleText() {
    if (this.success) {
      return 'Password Changed'
    }
    if (this.expired) {
      return 'Link Expired'
    }
    return 'New Password'
  }

  get messageText() {
    if (this.expired) {
      return 'This link has expired, please request a new password reset email.'
    }
    if (this.success) {
      return 'Password was successfully changed!'
    }
    return ''
  }

  get buttonText() {
    if (this.success) {
      return 'GO TO LOGIN'
    }
    if (this.expired) {
      return 'PASSWORD RESET'
    }
    return 'SET PASSWORD'
  }

  get buttonAction() {
    if (this.success) {
      return this.goToLogin
    }
    if (this.expired) {
      return this.goToPasswordReset
    }
    return this.setPassword
  }

  async setPassword() {
    if (this.form.validate()) {
      this.busy = true
      this.success = false
      this.expired = false
      this.newPasswordFormModel.token = window.location.hash.substr(1)
      AuthService.postNewPassword(this.newPasswordFormModel)
        .then(() => {
          this.success = true
          this.form.reset()
        })
        .catch(() => {
          this.expired = true
        })
        .finally(() => {
          this.busy = false
        })
    }
  }

  goToLogin() {
    this.$router.push({ name: RouteNames.LOGIN })
  }

  goToPasswordReset() {
    this.$router.push({ name: RouteNames.PASSWORD_RESET })
  }

  equalsPassword(v: string) {
    return v === this.newPasswordFormModel.password || 'Must match password.'
  }
}
