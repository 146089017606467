




























import { Component, Prop, Vue } from 'vue-property-decorator'
import IconWithText from '@/components/form/IconWithText.vue'
import PlatformModel from '@/models/provider/platform-model'
import FeatureList from '@/components/feature/FeatureList.vue'

@Component({
  components: {
    IconWithText,
    FeatureList,
  },
})
export default class PlatformMotive extends Vue {
  @Prop({ required: true }) platform!: PlatformModel

  get iconSize() {
    if (this.$vuetify.breakpoint.lgAndUp) return '1.9em'
    if (this.$vuetify.breakpoint.mdAndUp) return '1.4em'
    return '1.5em'
  }
}
