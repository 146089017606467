



















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const NotificationModule = namespace('notificationStore')

@Component
export default class SnackbarNotification extends Vue {
  @NotificationModule.State('color')
  color!: string

  @NotificationModule.State('message')
  message!: string

  @NotificationModule.State('timeout')
  timeout!: number

  @NotificationModule.State('displayNotification')
  displayNotification!: boolean

  @NotificationModule.Mutation('updateDisplay')
  updateDisplay!: Function

  get display() {
    return this.displayNotification
  }

  setDisplay() {
    this.updateDisplay(!this.display)
  }
}
