import LoginFormModel from '@/models/form/login-form-model'
import SignUpFormModel from '@/models/form/sign-up-form-model'
import ClientContextModel from '@/models/auth/client-context-model'
import AccessTokenResponseModel from '@/models/auth/access-token-response-model'
import PasswordManagementFormModel from '@/models/form/password-management-form-model'
import NewPasswordFormModel from '@/models/form/new-password-form-model'
import AuthCodeResponseModel from '@/models/auth/auth-code-response-model'
import RouteNames from '@/router/route-names'
import axios from 'axios'

export default {
  async postSignUp(signUpRequest: SignUpFormModel): Promise<AccessTokenResponseModel> {
    const response = await axios.post('/api/auth/sign-up', signUpRequest, { withCredentials: true })
    return response.data
  },

  async postLogin(loginRequest: LoginFormModel): Promise<AccessTokenResponseModel> {
    const response = await axios.post('/api/auth/login', loginRequest, { withCredentials: true })
    return response.data
  },

  async postAuth(): Promise<AccessTokenResponseModel> {
    const response = await axios.post('/api/auth', {}, { withCredentials: true })
    return response.data
  },

  async postLogout(): Promise<number> {
    const response = await axios.post('/api/auth/logout', {}, { withCredentials: true })
    return response.status
  },

  async postChangePassword(passwordManagementForm: PasswordManagementFormModel): Promise<number> {
    const response = await axios.post('/api/auth/change-password', passwordManagementForm, { withCredentials: true })
    return response.status
  },

  async postPasswordReset(username: string): Promise<number> {
    const response = await axios.post('/api/auth/password-reset', { username })
    return response.data
  },

  async postNewPassword(newPasswordForm: NewPasswordFormModel): Promise<number> {
    const response = await axios.post('/api/auth/new-password', newPasswordForm)
    return response.status
  },

  async postAuthCode(clientToken: string): Promise<AuthCodeResponseModel> {
    const response = await axios.post('/api/auth/auth-code', { clientToken }, { withCredentials: true })
    return response.data
  },

  async getClientContext(clientToken: string): Promise<ClientContextModel> {
    const response = await axios.post('/api/auth/client-context', { clientToken })
    return response.data
  },

  isRouteToLoginOnFailedAuth(currentRouteName: string): boolean {
    return currentRouteName === RouteNames.PROFILE_WITH_NOTIFICATION
      || currentRouteName === RouteNames.PROFILE_WITHOUT_NOTIFICATION
      || currentRouteName === RouteNames.PASSWORD_MANAGEMENT
      || currentRouteName === RouteNames.VERIFICATION_PROFILE
      || currentRouteName === RouteNames.VERIFICATION_PICTURE
      || currentRouteName === RouteNames.VERIFICATION_ID
      || currentRouteName === RouteNames.VERIFICATION_HPCSA
      || currentRouteName === RouteNames.VERIFICATION_PRACTICE
  },

  isAccessTokenIssuingRequest(url: string): boolean {
    return url === '/api/auth'
      || url === '/api/auth/login'
      || url === '/api/auth/sign-up'
  },

  isAuthRequest(url: string): boolean {
    return url === '/api/auth'
  },
}
