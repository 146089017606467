import {
  Module, Mutation, MutationAction, VuexModule,
} from 'vuex-module-decorators'
import { ProfileStoreStateModel } from '@/models/store/states/profile-store-state-model'
import AuthService from '@/services/api/auth-service'
import IdentityService from '@/services/api/identity-service'
import ProfileModel from '@/models/profile/profile-model'
import SignUpFormModel from '@/models/form/sign-up-form-model'
import LoginFormModel from '@/models/form/login-form-model'
import UpdateProfileRequestModel from '@/models/profile/update-profile-request-model'
import ClientContextModel from '@/models/auth/client-context-model'
import AccessTokenResponseModel from '@/models/auth/access-token-response-model'
import axios from 'axios'

interface DisciplineModel {
  id: number;
  name: string;
}

function getLoggedIn(): boolean {
  const temp = localStorage.getItem('loggedIn')
  if (!temp) {
    return false
  }
  return temp === 'true'
}

function getClientToken(): string {
  const token = localStorage.getItem('clientToken')
  if (!token) {
    return ''
  }
  return token
}

function setClientToken(token: string) {
  localStorage.setItem('clientToken', token)
}

@Module({
  namespaced: true,
  name: 'ProfileStore',
})
export default class ProfileStore extends VuexModule {
  userProfile: ProfileModel = new ProfileModel()

  clientContext: ClientContextModel = new ClientContextModel()

  verificationResponse: Map<string, boolean> = new Map()

  loggedIn: boolean = getLoggedIn()

  authResponse: AccessTokenResponseModel = new AccessTokenResponseModel()

  clientToken: string = getClientToken()

  disciplines: string[] = []

  @Mutation
  updateLoginState(loggedIn: boolean) {
    this.loggedIn = loggedIn
    localStorage.setItem('loggedIn', 'false')
  }

  @Mutation
  updateUserProfile(newForm: ProfileModel) {
    this.userProfile = newForm
  }

  @MutationAction({ mutate: ['userProfile'] })
  async fetchProfile() {
    return { userProfile: await IdentityService.getProfile() }
  }

  @MutationAction({ mutate: ['authResponse', 'userProfile', 'loggedIn'] })
  async authRequest() {
    try {
      const response = await AuthService.postAuth()
      const profile = await IdentityService.getProfile()
      localStorage.setItem('loggedIn', 'true')
      return { authResponse: response, userProfile: profile, loggedIn: true }
    } catch (e) {
      localStorage.setItem('loggedIn', 'false')
      throw e
    }
  }

  @MutationAction({ mutate: ['userProfile', 'loggedIn'] })
  async signUpRequest(signupForm: SignUpFormModel) {
    await AuthService.postSignUp(signupForm)
    localStorage.setItem('loggedIn', 'true')
    return { userProfile: await IdentityService.getProfile(), loggedIn: true }
  }

  @MutationAction({ mutate: ['userProfile', 'loggedIn'] })
  async loginRequest(loginForm: LoginFormModel) {
    try {
      await AuthService.postLogin(loginForm)
      localStorage.setItem('loggedIn', 'true')
      localStorage.setItem('stayLoggedIn', `${loginForm.keepUserLoggedIn}`)
      return { userProfile: await IdentityService.getProfile(), loggedIn: true }
    } catch (e) {
      if (e.response.status === 403) {
        localStorage.setItem('loggedIn', 'true')
        localStorage.setItem('stayLoggedIn', `${loginForm.keepUserLoggedIn}`)
        const currentState = this.state as ProfileStoreStateModel
        return { userProfile: currentState.userProfile, loggedIn: true }
      }
      localStorage.setItem('loggedIn', 'false')
      throw e
    }
  }

  @MutationAction({ mutate: ['userProfile', 'loggedIn'] })
  async logoutRequest() {
    await AuthService.postLogout()
    localStorage.setItem('loggedIn', 'false')
    return { userProfile: new ProfileModel(), loggedIn: false }
  }

  @MutationAction({ mutate: ['userProfile'] })
  async updateProfileRequest() {
    const currentState = this.state as ProfileStoreStateModel
    if (currentState.userProfile.isDefault()) {
      return { userProfile: new ProfileModel() }
    }
    const updateProfileRequest = new UpdateProfileRequestModel(currentState.userProfile)
    const updatedProfile = await IdentityService.postUpdateProfile(updateProfileRequest)
    return { userProfile: updatedProfile }
  }

  @MutationAction({ mutate: ['verificationResponse'] })
  async postVerificationInformation(isHpcsa: boolean) {
    const currentState = this.state as ProfileStoreStateModel
    const data = new FormData()
    data.append('pictureTaken', new Blob([currentState.userProfile.profileImage]))
    if (isHpcsa) {
      data.append('documentImage', new Blob([currentState.userProfile.hpcsaDocumentImage]))
    } else {
      data.append('documentImage', new Blob([currentState.userProfile.idDocumentImage]))
    }
    if (isHpcsa) {
      return { verificationResponse: await IdentityService.postVerifyHpcsaDetails(data) }
    }
    return { verificationResponse: await IdentityService.postVerifyIdDetails(data) }
  }

  @MutationAction({ mutate: ['clientToken', 'clientContext'] })
  async updateClientContext() {
    let token: string = window.location.hash.substr(1)
    if (token === '') {
      token = getClientToken()
    } else {
      setClientToken(token)
    }
    if (token !== '') {
      return { clientToken: token, clientContext: await AuthService.getClientContext(token) }
    }
    return { clientToken: '', clientContext: new ClientContextModel() }
  }

  @MutationAction({ mutate: ['clientContext'] })
  async followClientContextRedirect() {
    const currentState = this.state as ProfileStoreStateModel
    if (currentState.clientToken !== '') {
      try {
        const authCodeResponse = await AuthService.postAuthCode(currentState.clientToken)
        window.location.replace(authCodeResponse.redirectUrl)
      } catch (e) {
        if (e.response.status !== 403) {
          throw e
        }
      } finally {
        setClientToken('')
      }
    }
    return { clientContext: new ClientContextModel() }
  }

  @MutationAction({ mutate: ['disciplines'] })
  async updateDisciplines() {
    const response = await axios.get('/api/discipline')
    return { disciplines: response.data.map((discipline: DisciplineModel) => discipline.name) }
  }
}
