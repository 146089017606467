





































































import { Component, Ref } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { VFormType } from '@/types/v-form-type'
import validationMixin from '@/mixins/validation-mixin'
import TextField from '@/components/form/TextField.vue'
import IconWithText from '@/components/form/IconWithText.vue'
import FormCard from '@/components/form/FormCard.vue'
import AuthService from '@/services/api/auth-service'

@Component({
  components: {
    FormCard,
    IconWithText,
    TextField,
  },
})
export default class PasswordReset extends mixins(validationMixin) {
  @Ref('passwordResetFormRef') passwordResetFormRef!: VFormType

  private username = ''

  private sent = false

  get form(): VFormType {
    return this.$refs.passwordResetFormRef as VFormType
  }

  resetPassword() {
    if (this.form.validate()) {
      this.sent = false
      AuthService.postPasswordReset(this.username)
      this.sent = true
    }
  }

  tryAgain() {
    this.sent = false
  }
}
