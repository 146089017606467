const verifyResponseProcessingService = function verifyResponseProcessing(
  results: Map<string, boolean>,
) {
  const errors: string[] = []
  const keys = Array.from(results.keys())
  keys.forEach((key) => {
    if (!results.get(key)) {
      errors.push(`${key} does not match`)
    }
  })
  return errors
}

export default verifyResponseProcessingService
