


















import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Provider from '@/components/provider/Provider.vue'
import ProviderModel from '@/models/provider/provider-model'
import OfferingPaginationModel from '@/models/offering/offering-pagination-model'

const ProviderModule = namespace('providerStore')
const LoadingIndicatorModule = namespace('loadingIndicatorStore')
const OfferingModule = namespace('offeringStore')

@Component({
  components: {
    Provider,
  },
})
export default class Category extends Vue {
  @Prop() categoryId!: number

  @Prop() categoryName!: string

  @Ref('providerRef')
  providerRef!: HTMLElement

  @ProviderModule.State('providersPerCategory')
  providersPerCategory!: Map<string, number[]>

  @ProviderModule.State('providerBatch')
  providerBatch!: ProviderModel[]

  @ProviderModule.Action('getBatchOfProviders')
  getBatchOfProviders!: Function

  @ProviderModule.Mutation('updateProviderNameCache')
  updateProviderIDPerCategory!: Function

  @ProviderModule.Action('getProviderIdsPerCategory')
  getProviderIdsPerCategory!: Function

  @LoadingIndicatorModule.Mutation('showLoadingIndicator')
  showLoadingIndicator!: Function

  @OfferingModule.State('offeringsByCategory')
  offeringsByCategory!: Map<string, OfferingPaginationModel>

  @OfferingModule.Mutation('resetOfferingsByCategory')
  resetOfferingsByCategory!: Function

  @OfferingModule.Action('loadOfferingsByCategory')
  loadOfferingsByCategory!: Function

  private alreadyLoading = false

  private currentIndex = 0

  private batchSize = 3

  private providers: ProviderModel[] = []

  get providersIds() {
    let ids = this.providersPerCategory.get(this.categoryId.toString())
    if (!ids) {
      ids = []
    }
    return ids
  }

  async created() {
    this.showLoadingIndicator(true)
    await this.getProviderIdsPerCategory(this.categoryId)
    await this.loadNextProviders()
    this.showLoadingIndicator(false)
  }

  mounted() {
    window.addEventListener('scroll', this.onScroll)
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }

  async onScroll() {
    if (this.providerRef) {
      const providerTop = this.providerRef.getBoundingClientRect().top
      const { innerHeight } = window
      if (
        ((providerTop - innerHeight) < (-1500 * this.currentIndex) && !this.alreadyLoading)
        && this.providers.length !== this.providersIds.length
      ) {
        await this.loadNextProviders()
      }
    }
  }

  async loadNextProviders() {
    this.alreadyLoading = true
    if (this.providersIds && this.currentIndex < this.providersIds.length) {
      await this.getBatchOfProviders(this.nextBatchIds())
      this.providerBatch.forEach((provider: ProviderModel) => {
        this.providers.push(provider)
      })
      await this.loadProvidersOfferings()
    }
    this.alreadyLoading = false
  }

  async loadProvidersOfferings() {
    this.providers.forEach((provider: ProviderModel) => {
      const offeringKey = `${this.categoryName}-${provider.name}`
      if (!this.offeringsByCategory.get(offeringKey)) {
        const offeringPaginationModel = new OfferingPaginationModel(
          (offeringKey),
          0,
          1,
          this.$vuetify.breakpoint.lgAndUp ? 4 : 3,
          provider.offeringIds,
          [],
        )
        this.loadOfferingsByCategory(offeringPaginationModel)
      }
    })
  }

  nextBatchIds() {
    let batch = this.providersIds.length - this.currentIndex
    if (batch > this.batchSize) {
      batch = this.batchSize
    }

    const idList = []
    for (let i = this.currentIndex; i < batch + this.currentIndex; i += 1) {
      idList.push(this.providersIds[i])
    }

    this.currentIndex += batch
    return idList
  }
}
