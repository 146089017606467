















































import {
  Component, Model, Prop, Watch,
} from 'vue-property-decorator'
import base64EncodeImage from '@/services/image-encoding-service'
import { mixins } from 'vue-class-component'
import BrowserTypeMixin from '@/mixins/browser-type-mixin'

const webcamErrors = {
  permission: 'It seems you did not give permission to use the webcam. <br/> To verify and access all that circamed has to offer please go into browser settings and give CircaMed permission to your webcam.'
    + '<br/> To turn on your webcam navigate to permissions under settings on your browser, find circamed under camera permission and change that to allow.'
    + '<br/> Alternatively some browsers display this in the address bar at the top of the page. Click the camera icon and select allow.',
  firefox: 'It seems that you are using firefox.<br/> Unfortunately firefox has errors accessing the webcam when other applications are already using it. <br/> To fix this issue please close all other applications which are currently using your webcam or alternatively complete your verification on Chrome',
  invalidWebcam: 'It seems that your webcam device is not working. Please try to verify refresh the page or alternatively verify on a device with a working webcam.',
}

@Component({
  methods: {
    base64EncodeImage,
  },
  components: {},
})
export default class VideoCaptureStream extends mixins(BrowserTypeMixin) {
  @Model('input', { required: true }) value!: ArrayBuffer

  @Prop({ type: Boolean }) hasPermission!: boolean

  @Prop({ required: true }) readonly title!: string

  @Prop() errorMessages!: string[]

  pictureTaken: ArrayBuffer = new ArrayBuffer(0)

  displayVideoFeed = false

  localStream!: MediaStream

  navigatorPermission = false

  permissionStatus!: PermissionStatus

  get permission() {
    return this.hasPermission && this.navigatorPermission
  }

  get video() {
    return this.$refs.videoElement as HTMLVideoElement
  }

  async created() {
    if (this.value.byteLength === 0) {
      this.displayVideoFeed = true
    }
    await this.currentPermissionStatus()
    if (this.hasPermission) {
      await this.getWebcamPermission()
    }
  }

  beforeDestroy() {
    this.stopRecording()
  }

  async currentPermissionStatus() {
    await navigator.permissions.query({ name: 'camera' }).then((permissionResponse) => {
      this.permissionStatus = permissionResponse
      this.navigatorPermission = permissionResponse.state !== 'denied'
      // eslint-disable-next-line no-param-reassign
      permissionResponse.onchange = ((event) => {
        if (event.type === 'change') {
          if (event && event.target) {
            const newStatus = event.target as PermissionStatus
            this.navigatorPermission = newStatus.state !== 'denied'
          } else {
            this.navigatorPermission = false
          }
        }
      })
    }).catch(() => {
      this.navigatorPermission = false
    })
  }

  stopRecording() {
    if (this.localStream) {
      this.localStream.getTracks()[0].stop()
    }

    if (this.permissionStatus) {
      this.permissionStatus.onchange = null
    }
  }

  toggleDisplayVideoFeed() {
    if (this.hasPermission) {
      this.displayVideoFeed = true
    }
  }

  async takePicture() {
    if (!this.permission) {
      this.$emit('error', webcamErrors.permission)
      return
    }
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    if (!context) {
      return
    }

    const width = this.video.clientWidth
    const height = this.video.clientHeight
    canvas.width = width
    canvas.height = height
    context.drawImage(this.video, 0, 0, width, height)

    canvas.toBlob(async (blob) => {
      if (blob) {
        this.pictureTaken = await blob.arrayBuffer()
        this.$emit('input', this.pictureTaken)
        this.displayVideoFeed = false
      } else {
        this.$emit('error', webcamErrors.invalidWebcam)
      }
    })
  }

  async getWebcamPermission() {
    navigator.mediaDevices.getUserMedia({ video: { width: 1280, height: 720 } })
      .then((stream) => {
        this.video.srcObject = stream
        this.localStream = stream
        this.video.play()
      })
      .catch(() => {
        if (this.isFirefoxBrowser) {
          this.$emit('error', webcamErrors.firefox)
        } else {
          this.$emit('error', webcamErrors.permission)
        }
      });
  }

  @Watch('permission')
  async updatedPermission() {
    if (this.permission) {
      await this.getWebcamPermission()
    }
  }
}
