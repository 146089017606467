var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.offering)?_c('router-link',{staticClass:"full-width",staticStyle:{"text-decoration":"none"},attrs:{"to":{
      name: _vm.ROUTES.OFFERING,
      params: {
        categoryId: _vm.offering.category.id,
        offeringId: _vm.offering.id.toString(),
        categoryName: _vm.offering.category.name
      }
  }}},[_c('v-row',{staticClass:"pa-5",staticStyle:{"max-height":"410px","min-height":"410px"},attrs:{"justify":"center"}},[_c('v-card',{staticClass:"topCard",attrs:{"min-width":"100%"}},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('v-layout',{attrs:{"justify-end":""}},[_c('v-img',{attrs:{"eager":"","max-width":"55","alt":((_vm.offering.name) + " descriptive companies logo"),"src":(_vm.environmentUrl + "/" + (_vm.offering.providerLogo))}})],1),_c('v-layout',{staticClass:"pb-4",attrs:{"justify-center":""}},[_c('h1',{staticClass:"offeringName"},[_vm._v(_vm._s(_vm.offering.name))])]),_c('v-layout',{staticClass:"pb-5",staticStyle:{"height":"200px"},attrs:{"justify-center":"","align-center":""}},[_c('v-img',{staticClass:"offeringImage",attrs:{"aspect-ratio":"1","max-width":_vm.$vuetify.breakpoint.mdAndUp ? '60%' : '70%',"alt":((_vm.offering.name) + " descriptive image"),"src":(_vm.environmentUrl + "/" + (_vm.offering.image))},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"loading":"","type":"image","max-width":"100%"}})]},proxy:true}],null,false,3676433444)})],1)],1)],1)],1),_c('v-card',{staticClass:"bottomCard",attrs:{"height":"15%","min-width":"100%"}},[(_vm.offering.price === null)?_c('v-icon',{attrs:{"color":"white"},domProps:{"textContent":_vm._s(_vm.$vuetify.icons.values.play)}}):_c('div',[_vm._v(" "+_vm._s(_vm.offering.price)+" ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }