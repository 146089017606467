export default class ProductModel {
  name = ''

  description = ''

  price = 0.0

  link = ''

  userId = ''

  constructor(name: string, description: string, price: number, link: string, userId: string) {
    this.name = name
    this.description = description
    this.price = price
    this.link = link
    this.userId = userId
  }
}
