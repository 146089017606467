import { render, staticRenderFns } from "./CircamedFooter.vue?vue&type=template&id=6554125f&scoped=true&"
import script from "./CircamedFooter.vue?vue&type=script&lang=ts&"
export * from "./CircamedFooter.vue?vue&type=script&lang=ts&"
import style0 from "./CircamedFooter.vue?vue&type=style&index=0&id=6554125f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6554125f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VThemeProvider } from 'vuetify/lib/components/VThemeProvider';
installComponents(component, {VCol,VFooter,VImg,VLayout,VRow,VThemeProvider})
