import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import ErrorModel from '@/models/error-model'

@Module({
  namespaced: true,
  name: 'ErrorStore',
})
export default class ErrorStore extends VuexModule {
  error = new ErrorModel(0, '', '')

  display = false

  @Mutation
  updateError(error: ErrorModel) {
    this.error = error
    this.display = true
  }

  @Mutation
  hide() {
    this.display = false
  }
}
