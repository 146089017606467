import { Module, MutationAction, VuexModule } from 'vuex-module-decorators'
import axios from 'axios'
import FeatureFlagModel from '@/models/feature/feature-flag-model'

@Module({
  namespaced: true,
  name: 'FeatureFlagStore',
})
export default class FeatureFlagStore extends VuexModule {
  featureFlags: FeatureFlagModel[] | undefined = undefined

  @MutationAction({ mutate: ['featureFlags'] })
  async getAllFeatureFlags() {
    const response = await axios.get('/api/feature-flags')
    return { featureFlags: response.data as FeatureFlagModel[] }
  }
}
