import FeatureModel from '@/models/provider/feature-model'
import BenefitModel from '@/models/provider/benefit-model'
import CategoryModel from '@/models/offering/category-model'

export default class ProviderModel {
  hasIllustration = false

  id = 0

  name = ''

  title = ''

  illustrationPath = ''

  longDescription = ''

  shortDescription = ''

  brokerDescription = ''

  logo = ''

  offeringIds: number[] = []

  features: FeatureModel[] = []

  benefits: BenefitModel[] = []

  category!: CategoryModel
}
