





















import { Component, Prop } from 'vue-property-decorator'
import screenSizeMixin from '@/mixins/screen-size-mixin'
import { mixins } from 'vue-class-component'

@Component
export default class DividerWithText extends mixins(screenSizeMixin) {
  @Prop({ type: String }) text!: string
}
