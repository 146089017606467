class Constants {
  PERSONAL_NEEDS_FORM_LEAD_PATH = 'personal-needs-form-id'

  ILLUSTRATIONS = {
    SOFTWARE: 'illustrations/family-wellbeing.png',
    PPS_PROVIDER: 'illustrations/family-security.png',
    INSURANCE: 'illustrations/insurance-policy.png',
    CIRCAMED: 'flower/flower-all.png',
    DEFAULT: 'illustrations/hero-illustration.png',
  }

  IMAGES = {
    STATIC_LOGOS: '/static-logos',
    STATIC_ILLUSTRATIONS: '/illustrations',
    STATIC_LANDING: '/static-landing',
  }

  SUPPORT_EMAIL = 'contact@logbox.co.za'
}

export default new Constants()
