import axios from 'axios'
import {
  VuexModule, Module, MutationAction,
} from 'vuex-module-decorators'
import PracticeTypeModel from '@/models/needscalculator/practice-type-model'
import GradeModel from '@/models/needscalculator/grade-model'
import RecommendationsModel from '@/models/needscalculator/recommendations-model'
import CalculatorFormModel from '@/models/needscalculator/needs-calculator-form-model'
import ValidationModel from '@/models/validation/validation-model'
import NeedsCalculatorStoreStateModel from '@/models/store/states/needs-calculator-store-state-model'
import SpecialityModel from '@/models/needscalculator/speciality-model'

const RECOMMENDATIONS_STORE_STORAGE_KEY = 'circamed-recommendations-store'

@Module({
  namespaced: true,
  name: 'NeedsCalculatorFormStore',
})
export default class NeedsCalculatorFormStore extends VuexModule {
  practiceTypes: PracticeTypeModel[] = []

  grades: GradeModel[] = []

  specialities: SpecialityModel[] = []

  recommendations: RecommendationsModel = new RecommendationsModel()

  validation: ValidationModel = new ValidationModel()

  constructor(module: VuexModule) {
    super(module)
    const recommendationsJSON = sessionStorage.getItem(RECOMMENDATIONS_STORE_STORAGE_KEY)
    this.recommendations = new RecommendationsModel()
    if (recommendationsJSON !== null) {
      this.recommendations = JSON.parse(recommendationsJSON) as RecommendationsModel
    }
  }

  @MutationAction({ mutate: ['validation'] })
  async clearValidation() {
    return { validation: new ValidationModel() }
  }

  @MutationAction({ mutate: ['practiceTypes'] })
  async updatePracticeTypes() {
    const response = await axios.get('/api/practice-types')
    return { practiceTypes: response.data as PracticeTypeModel[] }
  }

  @MutationAction({ mutate: ['grades'] })
  async updateGrades() {
    const response = await axios.get('/api/grades')
    return { grades: response.data as GradeModel[] }
  }

  @MutationAction({ mutate: ['specialities'] })
  async updateSpecialities() {
    const response = await axios.get('/api/specialities')
    return { specialities: response.data as SpecialityModel[] }
  }

  @MutationAction({ mutate: ['recommendations', 'validation'] })
  async submitForm(calculatorForm: CalculatorFormModel) {
    const state = this.state as NeedsCalculatorStoreStateModel

    return axios.post('/api/needs-calculator-forms', calculatorForm)
      .then((response) => {
        sessionStorage.setItem(RECOMMENDATIONS_STORE_STORAGE_KEY, JSON.stringify(response.data))
        return {
          recommendations: response.data as RecommendationsModel,
          validation: new ValidationModel(),
        }
      }).catch((error) => ({
        recommendations: state.recommendations,
        validation: error.response.data as ValidationModel,
      }))
  }
}
