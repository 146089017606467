









































































import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import FormCard from '@/components/form/FormCard.vue'
import IconWithText from '@/components/form/IconWithText.vue'
import TextField from '@/components/form/TextField.vue'
import ContactNumberField from '@/components/form/ContactNumberField.vue'
import SelectField from '@/components/form/SelectField.vue'
import VerifyDocumentUpload from '@/components/verificationuploadslots/VerifyDocumentUpload.vue'
import SupportEmail from '@/components/general/SupportEmail.vue'
import verificationStepMixin from '@/mixins/verification-step-mixin'
import validationMixin from '../../mixins/validation-mixin'

@Component({
  components: {
    VerifyDocumentUpload,
    FormCard,
    IconWithText,
    TextField,
    ContactNumberField,
    SelectField,
    SupportEmail,
  },
})
export default class VerificationID extends mixins(
  validationMixin,
  verificationStepMixin,
) {
  verificationError = 'The ID number on the image and the ID number given do not match'

  displayErrorDialog = false

  isVerifying = false

  manualVerificationOption = false

  get isVerified() {
    return this.userProfile.isIdPassportDocumentVerified
  }

  set isVerified(newVal: boolean) {
    this.userProfile.isIdPassportDocumentVerified = newVal
  }

  async created() {
    this.isVerified = this.userProfile.isIdPassportDocumentVerified
  }

  nextPage() {
    this.updateStore()
    this.$emit('next')
  }

  previousPage() {
    this.$emit('previous')
  }

  showErrors(errorMessage: string) {
    this.$emit('error', errorMessage)
    this.manualVerificationOption = true
    this.isVerifying = false
  }

  updateIdPicture() {
    this.isVerifying = false
    this.updateStore()
    this.isVerified = true
    this.manualVerificationOption = false
  }
}
