// eslint-disable-next-line import/prefer-default-export
export const TITLES = [
  'Dr',
  'Mr',
  'Mrs',
  'Ms',
  'Miss',
  'Mx',
  'Other',
]
