






























































import { Component } from 'vue-property-decorator'
import CompanyGeneralLinks from '@/components/footer/CompanyGeneralLinks.vue'
import { mixins } from 'vue-class-component'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'

@Component({
  components: {
    CompanyGeneralLinks,
  },
})
export default class CircamedFooter extends mixins(EnvironmentUrlMixin) {
  private year = 2020

  created(): void {
    this.year = new Date().getFullYear()
  }
}
