























































































































import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import TabsMenu from '@/components/header/AccountTabsMenu.vue'
import DrawerOptions from '@/components/header/DrawerOptions.vue'
import NavigationBarMenu from '@/components/header/NavigationBarMenu.vue'
import CategoriesNavigation from '@/components/offering/CategoriesNavigation.vue'
import screenSizeMixin from '@/mixins/screen-size-mixin'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'

@Component({
  components: {
    TabsMenu,
    DrawerOptions,
    NavigationBarMenu,
    CategoriesNavigation,
  },
})
export default class CircamedHeader extends mixins(screenSizeMixin, EnvironmentUrlMixin) {
  private drawerOpen = false

  openDrawer() {
    this.drawerOpen = true
  }
}
