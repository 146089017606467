












































































































































import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { VFormType } from '@/types/v-form-type'
import FormCard from '@/components/form/FormCard.vue'
import IconWithText from '@/components/form/IconWithText.vue'
import TextField from '@/components/form/TextField.vue'
import ContactNumberField from '@/components/form/ContactNumberField.vue'
import SelectField from '@/components/form/SelectField.vue'
import VideoCaptureStream from '@/components/verificationuploadslots/VideoStreamCapture.vue'
import SupportEmail from '@/components/general/SupportEmail.vue'
import RouteNames from '@/router/route-names'
import verificationStepMixin from '@/mixins/verification-step-mixin'
import validationMixin from '../../mixins/validation-mixin'

@Component({
  components: {
    FormCard,
    IconWithText,
    TextField,
    ContactNumberField,
    SelectField,
    VideoCaptureStream,
    SupportEmail,
  },
})
export default class VerificationProfilePicture extends mixins(
  validationMixin,
  verificationStepMixin,
) {
  @Prop() stepIndex!: number

  getWebcamPermission = true

  hasPermission = false

  canProgress = false

  manualVerificationOption = false

  progress = 50

  get form(): VFormType {
    return this.$refs.profilePictureForm as VFormType
  }

  async created() {
    if (
      this.userProfileStored.isHpcsaDocumentVerified
      && this.userProfileStored.isIdPassportDocumentVerified
    ) {
      this.$emit('complete')
      this.getWebcamPermission = false
      await this.$router.push({
        name: RouteNames.PROFILE_WITH_NOTIFICATION,
      })
    }
    if (this.userProfile.profileImage.byteLength) {
      this.canProgress = true
    }
  }

  updateProfilePicture() {
    this.updateStore()
    this.canProgress = true
  }

  giveAccess() {
    this.hasPermission = true
    this.getWebcamPermission = false
  }

  showErrors(errorMessage: string) {
    this.manualVerificationOption = true
    this.$emit('error', errorMessage)
  }

  next() {
    this.updateStore()
    this.$emit('next')
  }

  previous() {
    this.$emit('previous')
  }

  skipVerification() {
    this.$emit('skip')
  }
}
