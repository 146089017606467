






























import {
  Component, Emit, Prop, Watch,
} from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import screenSizeMixin from '@/mixins/screen-size-mixin'
import validatableMixin from 'vuetify/lib/mixins/validatable'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

@Component({
  components: {
    VuePhoneNumberInput,
  },
})
export default class ContactNumberField extends mixins(screenSizeMixin, validatableMixin) {
  @Prop() readonly label!: string

  @Prop() readonly value!: string

  @Prop({ type: Boolean }) dark!: boolean

  @Prop({ default: () => [] }) rules!: Array<Function>

  @Prop({ default: 'ZA' }) defaultCountryCode!: string

  private phoneNumber = this.value

  private errorMessage = ''

  private isValid = true

  // eslint-disable-next-line
  testRules(value?: any) {
    return this.rules.every((rule: Function) => {
      const ruleResult = rule(value)
      if (ruleResult !== true) {
        this.errorMessage = ruleResult
        return false
      }
      return true
    })
  }

  validate(force = false): boolean {
    if (force) {
      this.isValid = this.testRules(this.phoneNumber) && this.isValid
    }
    return this.isValid
  }

  @Watch('value')
  updatePhoneNumber() {
    this.phoneNumber = this.value
  }

  @Emit('input')
  // eslint-disable-next-line
  onUpdate(payload: any): string | null {
    if (this.testRules(payload.phoneNumber)) {
      if (!payload.phoneNumber || payload.isValid) {
        this.isValid = true
        this.errorMessage = ''
        return payload.formattedNumber
      }
      if (/[0-9]/.test(payload.phoneNumber)) {
        this.errorMessage = 'Phone number is in an incorrect format.'
      }
    }
    this.isValid = false
    return null
  }
}
