import FeatureFlagModel from '@/models/feature/feature-flag-model'

export const FEATURE_FLAGS = {
  PERSONAL_NEEDS_CALCULATOR: 'Personal Needs Calculator',
  SIGNUP_WITH_LOGBOX: 'Sign up with LogBox',
  VERIFICATION_LOGBOX: 'Verification LogBox',
  ADD_TO_CART: 'Add to Cart',
  VIEW_CART: 'View Cart',
}

function findFeatureFlag(featureFlags: FeatureFlagModel[],
  featureName: string): FeatureFlagModel | undefined {
  return featureFlags.find((feature) => feature.name === featureName)
}

export const isFeatureEnabled = function featureIsEnabled(featureFlags: FeatureFlagModel[],
  featureName: string) {
  if (!featureFlags) return false

  const feature: FeatureFlagModel | undefined = findFeatureFlag(featureFlags, featureName)

  return feature ? feature.enabled : true
}
