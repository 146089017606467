import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  namespaced: true,
  name: 'NotificationStore',
})
export default class NotificationStore extends VuexModule {
  color = 'primary'

  message = ''

  timeout = 5000

  displayNotification = false

  @Mutation
  updateMessage(newMessage: string) {
    this.message = newMessage
    this.displayNotification = true
  }

  @Mutation
  updateColor(newColor: string) {
    this.color = newColor
  }

  @Mutation
  updateDisplay(newVal: boolean) {
    this.displayNotification = newVal
  }
}
