































































































































import { Component, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { FEATURE_FLAGS, isFeatureEnabled } from '@/services/feature-flag-service'
import screenSizeMixin from '@/mixins/screen-size-mixin'
import RecommendationCard from '@/components/offering/RecommendationCard.vue'
import DropdownButton from '@/components/form/DropdownButton.vue'
import PersonalNeedsBasedCalculator from '@/components/needscalculator/PersonalNeedsBasedCalculator.vue'
import RecommendationsModel from '@/models/needscalculator/recommendations-model'
import FeatureFlagModel from '@/models/feature/feature-flag-model'
import OfferingModel from '@/models/offering/offering-model'
import RouteNames from '@/router/route-names'

const NeedsCalculatorFormModule = namespace('needsCalculatorFormStore')
const OfferingModule = namespace('offeringStore')
const FeatureFlagModule = namespace('featureFlagStore')

@Component({
  components: {
    DropdownButton,
    RecommendationCard,
    PersonalNeedsBasedCalculator,
  },
})
export default class Recommendations extends mixins(screenSizeMixin) {
  @NeedsCalculatorFormModule.State('recommendations')
  recommendations!: RecommendationsModel

  @OfferingModule.State('recommendedOfferings')
  recommendedOfferings!: OfferingModel[]

  @OfferingModule.Mutation('resetRecommendedOfferings')
  resetRecommendedOfferings!: Function

  @OfferingModule.Action('appendRecommendedOfferings')
  appendRecommendedOfferings!: Function

  @FeatureFlagModule.State('featureFlags')
  featureFlags!: FeatureFlagModel[]

  private offeringsPage = 0

  private offeringsPageSize = 3

  private offeringsAvailable = false

  private display = false

  @Watch('recommendations')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  recommendationsUpdate(value: RecommendationsModel) {
    this.resetRecommendedOfferings()
    this.offeringsPage = 0
    this.offeringsAvailable = false
    this.appendNextOfferingsPage()
  }

  async appendNextOfferingsPage() {
    const first = this.offeringsPage * this.offeringsPageSize
    let last = (this.offeringsPage + 1) * this.offeringsPageSize
    if (last > this.recommendations.recommendedOfferingIds.length) {
      last = this.recommendations.recommendedOfferingIds.length
    }

    const offeringIds = this.recommendations.recommendedOfferingIds.slice(first, last)
    if (last >= (this.recommendations.recommendedOfferingIds.length - 1)) {
      this.offeringsAvailable = false
    } else {
      this.offeringsPage += 1
      this.offeringsAvailable = true
    }

    await this.appendRecommendedOfferings(offeringIds.toString())
  }

  get needsCalculatorFlag() {
    return isFeatureEnabled(this.featureFlags, FEATURE_FLAGS.PERSONAL_NEEDS_CALCULATOR)
  }

  async created() {
    if (!this.needsCalculatorFlag) {
      await this.$router.push({
        name: RouteNames.HOME,
      })
    } else {
      if (this.$vuetify.breakpoint.xlOnly || this.$vuetify.breakpoint.smOnly) {
        this.offeringsPageSize = 4
      }
      this.resetRecommendedOfferings()
      await this.appendNextOfferingsPage()
    }
  }
}
