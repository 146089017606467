



























































import {
  Component, Model, Prop, Vue,
} from 'vue-property-decorator'
import FormCard from '@/components/form/FormCard.vue'

@Component({
  components: {
    FormCard,
  },
})
export default class LoginSignupDialog extends Vue {
  @Model('change', { type: Boolean }) display!: boolean

  @Prop({ type: Boolean }) loading!: boolean

  @Prop() dialogHeading!: string

  @Prop() dialogText!: string

  @Prop() buttonText!: string

  closeDialogBox() {
    if (this.loading) return
    this.$emit('change', false)
    this.$emit('errorAction', false)
  }
}
