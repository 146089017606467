// eslint-disable-next-line import/prefer-default-export
import Icon from '@/components/icon/Icon.vue'

// The component is set to Icon for all custom icons provided by the Tusk team.
// This was done to allow passing of props to set which icon is used by the component
const ICONS = {
  aboutUs: 'mdi-information',
  activeEngagement: {
    component: Icon,
    props: {
      iconFile: 'active-engagement.svg',
      size: '3.5rem',
    },
  },
  applications: {
    component: Icon,
    props: {
      iconFile: 'applications.svg',
      size: '3.5rem',
    },
  },
  benefitCircle: 'mdi-checkbox-blank-circle',
  bill: {
    component: Icon,
    props: {
      iconFile: 'bill-your-clients.svg',
      size: '3.5rem',
    },
  },
  betterDeals2: {
    component: Icon,
    props: {
      iconFile: 'better-deals-2.svg',
      size: '3.5rem',
    },
  },
  betterDeals3: {
    component: Icon,
    props: {
      iconFile: 'better-deals-3.svg',
      size: '3.5rem',
    },
  },
  bundledOfferings: {
    component: Icon,
    props: {
      iconFile: 'bundled-offerings.svg',
      size: '3.5rem',
    },
  },
  cart: 'mdi-cart-outline',
  cartPlus: 'mdi-cart-plus',
  checkboxOn: 'mdi-check',
  chevronLeft: 'mdi-chevron-left',
  chevronRight: 'mdi-chevron-right',
  connectToBroker: {
    component: Icon,
    props: {
      iconFile: 'connect-broker.svg',
      size: '3.5rem',
    },
  },
  contactUs: {
    component: Icon,
    props: {
      iconFile: 'contact-us.svg',
      size: '3.5rem',
    },
  },
  dataShare: {
    component: Icon,
    props: {
      iconFile: 'data-share.svg',
      size: '3.5rem',
    },
  },
  digitise: {
    component: Icon,
    props: {
      iconFile: 'digitise.svg',
      size: '3.5rem',
    },
  },
  edit: 'mdi-square-edit-outline',
  downArrow: 'mdi-triangle',
  enterpriseGradeOnboarding: {
    component: Icon,
    props: {
      iconFile: 'enterprise-grade-onboarding.svg',
      size: '3.5rem',
    },
  },
  financialSecurity: {
    component: Icon,
    props: {
      iconFile: 'financial-security.svg',
      size: '3.5rem',
    },
  },
  help: 'mdi-help-circle',
  hidePassword: 'mdi-eye-off',
  home: 'mdi-home-circle',
  HPCSA: {
    component: Icon,
    props: {
      iconFile: 'hpcsa.svg',
      size: '3.5rem',
    },
  },
  loggedIn: 'mdi-checkbox-blank-circle',
  login: {
    component: Icon,
    props: {
      iconFile: 'login-lock.svg',
    },
  },
  orders: 'mdi-truck',
  passwordReset: 'mdi-lock-question',
  payment: {
    component: Icon,
    props: {
      iconFile: 'payment-card.svg',
    },
  },
  play: 'mdi-play',
  profile: {
    component: Icon,
    props: {
      iconFile: 'profile-picture-doctor.svg',
    },
  },
  placeholderIcon: 'mdi-checkbox-blank-circle',
  showPassword: 'mdi-eye',
  securePurchase: {
    component: Icon,
    props: {
      iconFile: 'secure-purchase.svg',
      size: '3.5rem',
    },
  },
  signUp: {
    component: Icon,
    props: {
      iconFile: 'sign-up.svg',
    },
  },
  singleIdentityManagement: {
    component: Icon,
    props: {
      iconFile: 'single-identity-management.svg',
      size: '3.5rem',
    },
  },
  sustainableIndemnity: {
    component: Icon,
    props: {
      iconFile: 'sustainable-indemnity.svg',
      size: '3.5rem',
    },
  },
  umbrella: {
    component: Icon,
    props: {
      iconFile: 'cover.svg',
      size: '3.5rem',
    },
  },
  unverified: 'mdi-alert-circle',
  unverifiedStepper: 'mdi-close',
  verified: 'mdi-check-circle',
  verifiedStepper: 'mdi-check',
}

export default ICONS
