

















































































































import { Component, Model, Prop } from 'vue-property-decorator'
import base64EncodeImage from '@/services/image-encoding-service'
import { ClickableType } from '@/types/clickable-type'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'
import { mixins } from 'vue-class-component'

@Component({
  methods: {
    base64EncodeImage,
  },
})
export default class ImageUpload extends mixins(EnvironmentUrlMixin) {
  @Model('input', { required: true }) value!: ArrayBuffer

  @Prop() readonly label!: string

  @Prop() readonly title!: string

  @Prop({ type: Boolean }) galleryOnly!: boolean

  @Prop({ type: Boolean }) uploadSlotOnly!: boolean

  @Prop({ type: Boolean }) verifying!: boolean

  @Prop({ type: Boolean }) verified!: boolean

  @Prop() errorMessages!: string[]

  @Prop({ default: 10485760 }) maxFileSizeBytes!: number

  @Prop() rules!: Array<Function>

  loadImageError = ''

  get selectClickable() {
    return this.$refs.fileInput as ClickableType
  }

  get placeholderImage() {
    return `${this.customIconsUrl}/profile-picture-doctor.svg`
  }

  get profilePicture() {
    if (!this.value.byteLength) {
      return this.placeholderImage
    }
    return base64EncodeImage(this.value)
  }

  get imageGradient() {
    if (this.verifying) {
      return 'to top right, rgba(87, 87, 87,.6), rgba(87, 87, 87,.6)'
    }
    if (this.verified) {
      return 'to top right, rgba(87, 87, 87,.8), rgba(87, 87, 87,.8)'
    }
    return 'to top right, rgba(0,0,0,0), rgba(0,0,0,0)'
  }

  get buttonText() {
    if (this.verifying) return 'Verifying'
    if (this.verified) return 'Verified'
    return 'Gallery'
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadImage(event: any) {
    if (event.target.files.length === 0) {
      return
    }
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (image) => {
      if (image.loaded >= this.maxFileSizeBytes) {
        this.loadImageError = `Images must be less than ${this.maxFileSizeBytes / (1024 * 1024)}MB`
        this.$emit('error', this.loadImageError)
        return
      }
      if (!image.target) {
        this.loadImageError = 'Could not load image'
        this.$emit('error', this.loadImageError)
        return
      }
      this.$emit('input', image.target.result)
      this.loadImageError = ''
    }
    reader.readAsArrayBuffer(file)
  }

  selectFile() {
    if (!this.verifying && !this.verified) {
      this.selectClickable.click()
    }
  }
}
