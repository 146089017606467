import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ICONS from '@/plugins/icons'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#12b2c2',
        primaryLighter: '#CAEFEE',
        primaryDarker: '#24637A',
        secondary: '#788b92',
        accent: '#ed4c31',
        gray: '#c1c1c1',
        anchor: '#010101',
        error: '#ff0000',
        info: '#010101',
      },
    },
  },
  icons: {
    values: ICONS,
  },
});
