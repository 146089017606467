









































































































import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { FEATURE_FLAGS, isFeatureEnabled } from '@/services/feature-flag-service'
import VueMarkdown from 'vue-markdown'
import LogBoxLink from '@/components/offering/detail/LogBoxLink.vue'
import OfferingModel from '@/models/offering/offering-model'
import FeatureFlagModel from '@/models/feature/feature-flag-model'
import ProfileModel from '@/models/profile/profile-model'
import ProductModel from '@/models/paymentportal/product-model'
import illustrationTypeMixin from '@/mixins/illustration-type-mixin'
import environmentUrlMixin from '@/mixins/environment-url-mixin'

const NotificationModule = namespace('notificationStore')
const FeatureFlagModule = namespace('featureFlagStore')
const ProfileModule = namespace('profileStore')
const ShoppingCartModule = namespace('shoppingCartStore')

@Component({
  components: {
    VueMarkdown,
    LogBoxLink,
  },
})
export default class OfferingDetail extends mixins(illustrationTypeMixin, environmentUrlMixin) {
  @Prop() offering!: OfferingModel

  @Prop() categoryName!: string

  @Prop() displayLogBoxLink!: boolean

  @Prop() isLogBoxPractice!: boolean

  @Prop() providerLogo!: string

  @FeatureFlagModule.State('featureFlags')
  featureFlags!: FeatureFlagModel[]

  @ProfileModule.State('userProfile')
  userProfile!: ProfileModel

  @ProfileModule.State('loggedIn')
  loggedIn!: boolean

  @NotificationModule.Mutation('updateMessage')
  updateMessage!: Function

  @NotificationModule.Mutation('updateColor')
  updateColor!: Function

  @ShoppingCartModule.Mutation('addToCart')
  add!: Function

  get addToCartFeatureFlag() {
    return isFeatureEnabled(this.featureFlags, FEATURE_FLAGS.ADD_TO_CART)
  }

  get viewCartFeatureFlag() {
    return isFeatureEnabled(this.featureFlags, FEATURE_FLAGS.VIEW_CART)
  }

  created() {
    this.illustrationDescriptor = this.categoryName
  }

  addToCart() {
    if (this.loggedIn) {
      try {
        this.add(new ProductModel(
          this.offering.name ? this.offering.name : '',
          this.offering.shortDescription ? this.offering.shortDescription : '',
          this.offering.price ? Number(this.offering.price) : 0.0,
          this.$router.currentRoute.path,
          this.userProfile.username,
        ))
        this.updateColor('green')
        this.updateMessage('Product added to cart.')
      } catch (error) {
        this.updateColor('red')
        this.updateMessage('An error occurred when trying to add this product to your cart.')
      }
    } else {
      this.updateColor('red')
      this.updateMessage('You must be logged in to add to your cart.')
    }
  }
}
