






























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PasswordField extends Vue {
  @Prop() readonly label!: string

  @Prop() readonly value!: string

  @Prop({ type: Boolean }) dark!: boolean

  @Prop() rules!: Array<Function>

  private viewPassword = false

  get color(): string {
    if (this.dark) {
      return 'accent'
    }
    return 'lighten1'
  }
}
