var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'field--text-light': !_vm.dark,
    'text--colour-dark': _vm.dark
  }},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3","sm":"1","md":"5","lg":"3"}},[_c('v-icon',{staticClass:"icon",attrs:{"color":"primary","size":_vm.iconSize},domProps:{"textContent":_vm._s(_vm.icon)}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"9","sm":"11","md":"7","lg":"9"}},[_c('div',{class:{
          'icon-text':true,
          'text-dark-alternative':_vm.dark,
          'text--title-responsive':_vm.isTitle,
          'text--subtitle-responsive':_vm.isSubtitle,
          'text--body-responsive':!_vm.isTitle && !_vm.isSubtitle,
        }},[_vm._v(" "+_vm._s(_vm.text)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }