import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { CATEGORY_NAMES } from '@/services/category-name-service'
import { TITLES } from '@/services/title-service'
import RouteNames from '@/router/route-names'
import CONSTANTS from '@/constants/constants'
import App from './App.vue'
import store from './store'
import router from './router/index'
import vuetify from './plugins/vuetify'
import '@/styles/general.css'
import '@/styles/buttons.css'
import '@/styles/form.css'
import '@/styles/text.css'
import '@/styles/extra-color-variables.css'

Vue.use(VueAxios, axios)

Vue.config.productionTip = false;

Vue.prototype.CATEGORY_NAMES = CATEGORY_NAMES
Vue.prototype.TITLES = TITLES
Vue.prototype.ROUTES = RouteNames
Vue.prototype.CONSTANTS = CONSTANTS

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
