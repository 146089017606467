



























import { Component, Prop } from 'vue-property-decorator'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'
import { mixins } from 'vue-class-component'

@Component
export default class ConnectBrokerCard extends mixins(EnvironmentUrlMixin) {
  @Prop() providerName!: string

 @Prop() cardDescription!: string

  @Prop({ type: Boolean }) waitingOnRequests!: boolean
}
