

















































































































































































import { Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import PlatformModel from '@/models/provider/platform-model'
import screenSizeMixin from '@/mixins/screen-size-mixin'
import { mixins } from 'vue-class-component'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'

const ProviderModule = namespace('providerStore')
const LoadingIndicatorModule = namespace('loadingIndicatorStore')

@Component
export default class About extends mixins(screenSizeMixin, EnvironmentUrlMixin) {
  @ProviderModule.State('platform')
  platform!: PlatformModel

  @ProviderModule.Action('getCircamedPlatform')
  getPlatform!: Function

  @LoadingIndicatorModule.Mutation('showLoadingIndicator')
  showLoadingIndicator!: Function

  get iconSizeList() {
    if (this.$vuetify.breakpoint.mdAndUp) return '1.8em'
    if (this.$vuetify.breakpoint.sm) return '1.7em'
    return '1.5em'
  }

  get iconSizeCards() {
    if (this.$vuetify.breakpoint.md) return '1.8em'
    if (this.$vuetify.breakpoint.sm) return '1.7em'
    return '1.5em'
  }

  async created() {
    this.showLoadingIndicator(true)
    await this.getPlatform()
    this.showLoadingIndicator(false)
  }
}
