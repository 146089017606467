import {
  Module, MutationAction, VuexModule,
} from 'vuex-module-decorators'
import axios from 'axios'
import ProductModel from '@/models/paymentportal/product-model'
import ShoppingCartModel from '@/models/paymentportal/shopping-cart-model'

@Module({
  namespaced: true,
  name: 'ShoppingCartStore',
})
export default class ShoppingCartStore extends VuexModule {
  shoppingCart: ShoppingCartModel = new ShoppingCartModel()

  @MutationAction({ mutate: ['shoppingCart'] })
  async addToCart(product: ProductModel) {
    const response = await axios.post('/api/shopping-cart/add-product', product)
    return { shoppingCart: response.data }
  }
}
