import { Component, Vue } from 'vue-property-decorator'

@Component
export default class IllustrationTypeMixin extends Vue {
  illustrationDescriptor!: string

  get illustration() {
    let retIllustration!: string
    switch (this.illustrationDescriptor.toLowerCase()) {
      case 'circamed':
        retIllustration = this.CONSTANTS.ILLUSTRATIONS.CIRCAMED
        break;
      case 'logbox':
      case 'software':
        retIllustration = this.CONSTANTS.ILLUSTRATIONS.SOFTWARE
        break;
      case 'pps':
        retIllustration = this.CONSTANTS.ILLUSTRATIONS.PPS_PROVIDER
        break;
      case 'insurance':
        retIllustration = this.CONSTANTS.ILLUSTRATIONS.INSURANCE
        break;
      default:
        retIllustration = this.CONSTANTS.ILLUSTRATIONS.DEFAULT
        break;
    }
    return retIllustration
  }
}
