import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import axios from 'axios'
import AccessTokenResponseModel from '@/models/auth/access-token-response-model'
import AuthService from '@/services/api/auth-service'
import RouteNames from '@/router/route-names'

const ProfileModule = namespace('profileStore')

@Component
export default class AuthInterceptorMixin extends Vue {
  @ProfileModule.State('authResponse')
  authResponse!: AccessTokenResponseModel

  @ProfileModule.Action('authRequest')
  authRequest!: Function

  @ProfileModule.Action('logoutRequest')
  logoutRequest!: Function

  createAuthInterceptor() {
    axios.interceptors.response.use(
      (response) => {
        const originalRequestUrl = response.config.url as string
        if (AuthService.isAccessTokenIssuingRequest(originalRequestUrl)) {
          setTimeout(this.authRequest, this.authResponse.accessTokenExpires * 1000)
        }
        return response
      },
      (error) => {
        const originalRequest = error.config
        if (this.$router.currentRoute.name !== RouteNames.LOGIN) {
          if (error.response.status === 401) {
            if (!AuthService.isAuthRequest(originalRequest.url)) {
              return this.authRequest()
                .then(() => axios.request(originalRequest))
            }
            this.logoutRequest()
            const currentRouteName = this.$router.currentRoute.name as string
            if (AuthService.isRouteToLoginOnFailedAuth(currentRouteName)) {
              this.$router.push({ name: RouteNames.LOGIN })
            }
          }
        }
        return Promise.reject(error)
      },
    )
  }
}
