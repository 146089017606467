




















































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { FEATURE_FLAGS, isFeatureEnabled } from '@/services/feature-flag-service'
import { STEPS } from '@/services/verification-steps-service'
import StepperButtons from '@/components/verification/stepper/StepperButtons.vue'
import FeatureFlagModel from '@/models/feature/feature-flag-model'
import Verification from '@/views/Verification.vue'
import CompleteVerificationContent from '@/components/verification/stepper/CompleteVerificationContent.vue'
import VerificationEmailSent from '@/components/verification/stepper/VerificationEmailSent.vue'
import ProfileModel from '@/models/profile/profile-model'
import RouteNames from '@/router/route-names'
import VerificationStepperSteps from '@/models/verificationstepper/verification-stepper-steps'

const FeatureFlagModule = namespace('featureFlagStore')
const ProfileModule = namespace('profileStore')

@Component({
  components: {
    StepperButtons,
    Verification,
    CompleteVerificationContent,
    VerificationEmailSent,
  },
})
export default class VerificationSteppers extends Vue {
  @FeatureFlagModule.State('featureFlags')
  featureFlags!: FeatureFlagModel[]

  @ProfileModule.State('loggedIn')
  loggedIn!: boolean

  @ProfileModule.State('userProfile')
  userProfile!: ProfileModel

  @ProfileModule.Action('fetchProfile')
  fetchProfile!: Function

  @Prop({ type: Boolean }) status!: boolean

  medicalPractitionerVerified = false

  emailVerifiedStatus = false

  firstCheck = false

  stepCounter = 1

  steps = STEPS

  signUpVerificationSteps = VerificationStepperSteps

  async created() {
    if (!this.loggedIn) {
      await this.$router.push({
        name: RouteNames.LOGIN,
      })
    }
    if (this.userProfile.isDefault()) {
      await this.fetchProfile()
    }
  }

  get logBoxVerificationStep() {
    return isFeatureEnabled(this.featureFlags, FEATURE_FLAGS.VERIFICATION_LOGBOX)
  }

  get finalStep() {
    return this.logBoxVerificationStep ? this.signUpVerificationSteps.LOGBOX_VERIFICATION_COMPLETE
      : this.signUpVerificationSteps.VERIFICATION_COMPLETE
  }

  get verification(): Verification {
    return this.$refs.verification as Verification
  }

  get verificationEmailSent(): VerificationEmailSent {
    return this.$refs.verificationEmailSent as VerificationEmailSent
  }

  previousStep() {
    if (this.stepCounter !== 1) {
      this.stepCounter -= 1
    }
  }

  nextStep() {
    if (this.stepCounter !== this.finalStep) {
      this.stepCounter += 1
    }
  }

  cancelledVerification() {
    this.stepCounter += 1
  }

  resendEmail() {
    this.verificationEmailSent.resendEmail()
  }

  @Watch('userProfile', { deep: true })
  check() {
    this.emailVerifiedStatus = this.status || this.userProfile.isEmailAddressVerified
    this.medicalPractitionerVerified = this.userProfile.isIdPassportDocumentVerified
      && this.userProfile.isHpcsaDocumentVerified
    if (
      this.userProfile.isEmailAddressVerified
      && this.userProfile.isIdPassportDocumentVerified
      && this.userProfile.isHpcsaDocumentVerified
      && !this.firstCheck
    ) {
      this.$router.replace({
        name: RouteNames.PROFILE_WITHOUT_NOTIFICATION,
      })
    }

    this.firstCheck = true
  }
}
