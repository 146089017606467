export default class TagModel {
  name = ''

  type=''

  constructor(t: string, n: string) {
    this.type = t;
    this.name = n;
  }
}
