
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class LogBoxLink extends Vue {
  @Prop() isLogBoxPractice!: boolean
}
