interface LooseObject {
  [key: string]: string | ArrayBuffer | boolean;
}

export default class ProfileModel {
  title: string | null

  firstName: string

  lastName: string

  username: string

  emailAddress: string

  mobileNumber: string

  idPassport: string

  discipline: string

  hpcsaNumber: string

  practiceNumber: string

  practicePhoneNumber: string

  isId: boolean

  acknowledgedTermsAndConditions: boolean

  givenUploadDocumentationConsent: boolean

  givenCmsaAccessCertificationConsent: boolean

  isIdPassportDocumentVerified: boolean

  isHpcsaDocumentVerified: boolean

  isEmailAddressVerified: boolean

  profileImage: ArrayBuffer

  idDocumentImage: ArrayBuffer

  hpcsaDocumentImage: ArrayBuffer

  constructor() {
    this.title = ''
    this.firstName = ''
    this.lastName = ''
    this.username = ''
    this.emailAddress = ''
    this.mobileNumber = ''
    this.idPassport = ''
    this.discipline = ''
    this.hpcsaNumber = ''
    this.practiceNumber = ''
    this.practicePhoneNumber = ''
    this.isId = true
    this.acknowledgedTermsAndConditions = false
    this.givenUploadDocumentationConsent = false
    this.givenCmsaAccessCertificationConsent = false
    this.isIdPassportDocumentVerified = false
    this.isEmailAddressVerified = false
    this.isHpcsaDocumentVerified = false
    this.profileImage = new ArrayBuffer(0)
    this.idDocumentImage = new ArrayBuffer(0)
    this.hpcsaDocumentImage = new ArrayBuffer(0)
  }

  deepCopy(): ProfileModel {
    const copy: LooseObject = {}

    Object.entries(this).forEach((entry) => {
      const [propertyName, propertyValue] = entry
      copy[propertyName] = propertyValue
    })

    const ret = new ProfileModel()
    Object.getOwnPropertyNames(copy).forEach((key) => {
      if (Object.getOwnPropertyDescriptor(ret, key)) {
        ret[key as keyof ProfileModel] = copy[key] as never
      }
    })

    return ret
  }

  isDefault() {
    const defaultModel = new ProfileModel()
    const currentProps = Object.getOwnPropertyNames(this)

    return currentProps
      .filter((key) => typeof this[key as keyof ProfileModel] !== 'object')
      .map(
        (key) => this[key as keyof ProfileModel] === defaultModel[key as keyof ProfileModel],
      ).every((val) => val)
  }
}
