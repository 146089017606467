









































import { Component, Prop, Vue } from 'vue-property-decorator'
import StepperButtons from '@/components/verification/stepper/StepperButtons.vue'

@Component({
  components: {
    StepperButtons,
  },
})
export default class CompleteVerificationContent extends Vue {
  @Prop({ type: Boolean }) verified!: boolean

  previousStep() {
    this.$emit('previous')
  }

  nextStep() {
    this.$emit('next')
  }
}
