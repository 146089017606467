






































































import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { namespace } from 'vuex-class'
import LeadGenerationForm from '@/components/lead/LeadGenerationForm.vue'
import DoubleFormCard from '@/components/form/DoubleFormCard.vue'
import FeatureList from '@/components/feature/FeatureList.vue'
import ProviderModel from '@/models/provider/provider-model'
import screenSizeMixin from '@/mixins/screen-size-mixin'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'

const ProviderModule = namespace('providerStore')

@Component({
  components: {
    LeadGenerationForm,
    DoubleFormCard,
    FeatureList,
  },
})
export default class Broker extends mixins(screenSizeMixin, EnvironmentUrlMixin) {
  @Prop() providerName!: string

  @ProviderModule.State('provider')
  provider!: ProviderModel

  @ProviderModule.Mutation('resetProvider')
  resetProvider!: Function

  @ProviderModule.Action('updateProviderByName')
  updateProviderByName!: Function

  private successfulRequest = false

  get featureIconSize() {
    if (this.$vuetify.breakpoint.smAndUp) return '1.8em'
    return '1.5em'
  }

  async created() {
    if (this.provider.name !== this.providerName) {
      this.resetProvider()
      await this.updateProviderByName(this.providerName)
    }
    this.successfulRequest = true
  }
}
