












import { Component, Prop, Vue } from 'vue-property-decorator'
import DefinitionModel from '@/models/general/definition-model'

@Component
export default class DefinitionList extends Vue {
  @Prop() definitions!: DefinitionModel[]
}
