import ProviderModel from '@/models/provider/provider-model'
import CategoryModel from '@/models/offering/category-model'

export default class OfferingModel {
  id: number | undefined = undefined

  name: string | undefined = undefined

  shortDescription: string | undefined = undefined

  longDescription: string | undefined = undefined

  image: string | undefined = undefined

  provider: ProviderModel | undefined = undefined

  category: CategoryModel | undefined = undefined

  canPurchase = false

  price: string | undefined = undefined

  providerLogo: string | undefined = undefined
}
