






















































































































































































































































































import { Component, Ref } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { VFormType } from '@/types/v-form-type'
import { FEATURE_FLAGS, isFeatureEnabled } from '@/services/feature-flag-service'
import screenSizeMixin from '@/mixins/screen-size-mixin'
import validationMixin from '@/mixins/validation-mixin'
import RouteNames from '@/router/route-names'
import TextField from '@/components/form/TextField.vue'
import PasswordField from '@/components/form/PasswordField.vue'
import IconWithText from '@/components/form/IconWithText.vue'
import DoubleFormCard from '@/components/form/DoubleFormCard.vue'
import DividerWithText from '@/components/form/DividerWithText.vue'
import PasswordRequirements from '@/components/form/PasswordRequirements.vue'
import ContactNumberField from '@/components/form/ContactNumberField.vue'
import PlatformMotive from '@/components/provider/PlatformMotive.vue'
import TsAndCsCheckbox from '@/components/lead/TsAndCsCheckbox.vue'
import FormResponseDialog from '@/components/dialog/FormResponseDialog.vue'
import LoginSignupDialog from '@/components/dialog/LoginSignupDialog.vue'
import SelectField from '@/components/form/SelectField.vue'
import FeatureFlagModel from '@/models/feature/feature-flag-model'
import SignUpFormModel from '@/models/form/sign-up-form-model'
import PlatformModel from '@/models/provider/platform-model'
import ClientContextModel from '@/models/auth/client-context-model'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'

const ProviderModule = namespace('providerStore')
const LoadingIndicatorModule = namespace('loadingIndicatorStore')
const FeatureFlagModule = namespace('featureFlagStore')
const ProfileModule = namespace('profileStore')

@Component({
  components: {
    PasswordRequirements,
    PlatformMotive,
    DividerWithText,
    DoubleFormCard,
    IconWithText,
    PasswordField,
    TextField,
    ContactNumberField,
    TsAndCsCheckbox,
    FormResponseDialog,
    LoginSignupDialog,
    SelectField,
  },
})
export default class SignUp extends mixins(
  validationMixin,
  screenSizeMixin,
  EnvironmentUrlMixin,
) {
  @Ref('signUpFormRef') signUpFormRef!: VFormType

  @ProfileModule.State('clientContext')
  clientContext!: ClientContextModel

  @ProfileModule.Action('updateClientContext')
  updateClientContext!: Function

  @LoadingIndicatorModule.Mutation('showLoadingIndicator')
  showLoadingIndicator!: Function

  @ProviderModule.State('platform')
  platform!: PlatformModel

  @ProviderModule.Action('getCircamedPlatform')
  getPlatform!: Function

  @FeatureFlagModule.State('featureFlags')
  featureFlags!: FeatureFlagModel[]

  @ProfileModule.State('loggedIn')
  loggedIn!: boolean

  @ProfileModule.State('errorStatus')
  errorStatus!: number

  @ProfileModule.Action('signUpRequest')
  signUpRequest!: Function

  @ProfileModule.Action('logoutRequest')
  logoutRequest!: Function

  @ProfileModule.State('disciplines')
  disciplines!: string[]

  @ProfileModule.Action('updateDisciplines')
  updateDisciplines!: Function

  @ProfileModule.Action('followClientContextRedirect')
  followClientContextRedirect!: Function

  private displayDialog = false

  private loading = false

  private idSelected = true

  private userNameChoice = 0

  private customUsername = ''

  private buttonText = ''

  private statusDialogHeading = ''

  private statusDialogText = ''

  private signUpForm = new SignUpFormModel()

  private usernameTypeIndex = {
    emailAddress: 1,
    mobileNumber: 2,
    custom: 3,
  }

  get form(): VFormType {
    return this.$refs.signUpFormRef as VFormType
  }

  get signUpWithLogBoxFlag() {
    return isFeatureEnabled(this.featureFlags, FEATURE_FLAGS.SIGNUP_WITH_LOGBOX)
  }

  async created() {
    this.updateClientContext()
    this.updateDisciplines()
    if (this.loggedIn) {
      await this.$router.replace({
        name: RouteNames.PROFILE_WITHOUT_NOTIFICATION,
      })
    } else {
      this.showLoadingIndicator(true)
      await this.getPlatform()
      this.showLoadingIndicator(false)
    }
  }

  equalsPassword(v: string) {
    return v === this.signUpForm.password || 'Must match password.'
  }

  logBoxSignUp() {
    return { name: RouteNames.LOGBOX_SIGN_UP }
  }

  async signUp() {
    this.updateUserName()
    if (this.form.validate()) {
      this.loading = true
      this.displayDialog = true

      try {
        await this.signUpRequest(this.signUpForm)
        this.displayDialog = false
        if (this.loggedIn) {
          await this.$router.push({
            name: RouteNames.SIGN_UP_VERIFICATION_EMAIL_SENT,
          })
        }
      } catch (e) {
        if (e.response.status === 409) {
          this.statusDialogHeading = 'You already have an account'
          this.statusDialogText = 'Would you like to login instead?'
          this.buttonText = 'Login'
        } else {
          this.statusDialogHeading = 'Oops something went wrong on our side'
          this.statusDialogText = 'Please try again later'
          this.buttonText = 'Ok'
        }
      }
      this.loading = false
    }
  }

  updateUserName() {
    switch (this.userNameChoice) {
      case this.usernameTypeIndex.emailAddress:
        this.signUpForm.username = this.signUpForm.emailAddress
        break
      case this.usernameTypeIndex.mobileNumber:
        this.signUpForm.username = this.signUpForm.mobileNumber
        break
      case this.usernameTypeIndex.custom:
      default:
        this.signUpForm.username = this.customUsername
        break
    }
  }

  isEmailNotEmpty() {
    if (this.userNameChoice === this.usernameTypeIndex.mobileNumber) {
      return this.signUpForm.mobileNumber !== '' || 'Mobile number is required'
    }
    return true
  }

  isMobileNumberNotEmpty() {
    if (this.userNameChoice === this.usernameTypeIndex.emailAddress) {
      return this.signUpForm.emailAddress !== '' || 'Email Address is required'
    }
    return true
  }

  isCustomNotEmpty() {
    if (this.userNameChoice === this.usernameTypeIndex.custom) {
      return this.customUsername !== '' || 'Username is required'
    }
    return true
  }

  errorAction() {
    if (this.buttonText === 'Login') {
      this.$router.push({ name: RouteNames.LOGIN })
    }
  }
}
