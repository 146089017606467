























import { Component, Vue } from 'vue-property-decorator'
import ROUTE_NAMES from '@/router/route-names'

@Component
export default class NavigationBarMenu extends Vue {
  ROUTES = ROUTE_NAMES
}
