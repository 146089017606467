
























































































































import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import LeadGenerationForm from '@/components/lead/LeadGenerationForm.vue'
import IconWithText from '@/components/form/IconWithText.vue'
import DividerWithText from '@/components/form/DividerWithText.vue'
import OfferingModel from '@/models/offering/offering-model'
import ProviderModel from '@/models/provider/provider-model'
import screenSizeMixin from '@/mixins/screen-size-mixin'
import { namespace } from 'vuex-class'

const ProfileModule = namespace('profileStore')

@Component({
  components: {
    LeadGenerationForm,
    IconWithText,
    DividerWithText,
  },
})
export default class ConnectToABrokerForm extends mixins(screenSizeMixin) {
  @ProfileModule.State('loggedIn')
  loggedIn !: boolean

  @Prop() offering!: OfferingModel

  @Prop() provider!: ProviderModel

  get brokerIconSize() {
    if (this.isDesktop) return '1.4em'
    return '1.8em'
  }
}
