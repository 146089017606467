









































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import CategoryModel from '@/models/offering/category-model'

const CategoryModule = namespace('categoryStore')
const ProfileModule = namespace('profileStore')

@Component
export default class DrawerOptions extends Vue {
  @CategoryModule.State('categories')
  categories!: CategoryModel[]

  @CategoryModule.Action('updateCategories')
  loadCategories!: Function

  @ProfileModule.State('loggedIn')
  loggedIn!: boolean

  async created() {
    this.loadCategories()
  }
}

