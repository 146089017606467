import ErrorModel from '@/models/error-model'

export const ERROR_MESSAGES = {
  NOT_FOUND: new ErrorModel(404, 'Not Found',
    'The resource being requested cannot be found.'),
  FORBIDDEN: new ErrorModel(403, 'Forbidden',
    'Access to this resource is not allowed.'),
  INTERNAL_ERROR: new ErrorModel(500, 'Internal Error',
    'Oops! Something went wrong.'),
  DEFAULT: new ErrorModel(0, '', 'An error was encountered'),
}

export function getError(status: number): ErrorModel {
  switch (status) {
    case 404:
      return ERROR_MESSAGES.NOT_FOUND
    case 403:
      return ERROR_MESSAGES.FORBIDDEN
    case 500:
      return ERROR_MESSAGES.INTERNAL_ERROR
    default:
      return ERROR_MESSAGES.DEFAULT
  }
}
