import { Module, MutationAction, VuexModule } from 'vuex-module-decorators'
import axios from 'axios'
import ContactUsFormModel from '@/models/form/contact-us-form-model'

@Module({
  namespaced: true,
  name: 'ContactUs',
})
export default class ContactUsStore extends VuexModule {
  response = ''

  @MutationAction({ mutate: ['response'] })
  async submit(contactUsForm: ContactUsFormModel) {
    const response = await axios.post('/api/contact-us', contactUsForm)
    return { response: response.data }
  }
}
