import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BrowserTypeMixin extends Vue {
  get isSamsungBrowser() {
    const indexOf = navigator.userAgent.indexOf('SamsungBrowser')
    return indexOf !== -1;
  }

  get isFirefoxBrowser() {
    const indexOf = navigator.userAgent.indexOf('Firefox')
    return indexOf !== -1;
  }
}
