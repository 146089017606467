import OfferingModel from '@/models/offering/offering-model'
import ProviderModel from '@/models/provider/provider-model'
import LeadModel from '@/models/lead/lead-model'
import ProfileModel from '@/models/profile/profile-model'

export default class LeadGenerationFormModel {
  termsAndConditions = false

  title: string | null = ''

  firstName = ''

  lastName = ''

  email = ''

  city = ''

  contactNumber = ''

  offering: OfferingModel = new OfferingModel()

  provider: ProviderModel = new ProviderModel()

  lead: LeadModel = new LeadModel()

  constructor(profile?: ProfileModel) {
    if (profile) {
      this.title = profile.title
      this.firstName = profile.firstName
      this.lastName = profile.lastName
      this.email = profile.emailAddress
      this.contactNumber = profile.mobileNumber
      this.lead.userName = profile.username
    }
  }
}
