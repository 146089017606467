
































































































import {
  Component, Model, Prop, Vue,
} from 'vue-property-decorator'
import FormCard from '@/components/form/FormCard.vue'
import { namespace } from 'vuex-class'

const ProfileModule = namespace('profileStore')

@Component({
  components: {
    FormCard,
  },
})
export default class FormResponseDialog extends Vue {
  @Model('change', { type: Boolean }) display!: boolean

  @Prop({ type: Boolean }) loading!: boolean

  @Prop() dialogHeading!: string

  @Prop() dialogText!: string

  @ProfileModule.State('loggedIn')
  loggedIn!: boolean

  closeDialogBox() {
    this.$emit('change', false)
    this.$emit('clearForm', false)
  }
}
