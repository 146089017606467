






























































































































































import { Component, Watch } from 'vue-property-decorator'
import FormCard from '@/components/form/FormCard.vue'
import IconWithText from '@/components/form/IconWithText.vue'
import ProfileModel from '@/models/profile/profile-model'
import DividerWithText from '@/components/form/DividerWithText.vue'
import { TITLES } from '@/services/title-service'
import { namespace } from 'vuex-class'
import { mixins } from 'vue-class-component'
import ValidationMixin from '@/mixins/validation-mixin'
import TextField from '@/components/form/TextField.vue'
import ContactNumberField from '@/components/form/ContactNumberField.vue'
import SelectField from '@/components/form/SelectField.vue'
import RouteNames from '@/router/route-names'

const ProfileModule = namespace('profileStore')

@Component({
  components: {
    FormCard,
    IconWithText,
    DividerWithText,
    TextField,
    ContactNumberField,
    SelectField,
  },
})
export default class UpdateProfile extends mixins(ValidationMixin) {
  @ProfileModule.State('userProfile')
  userProfile!: ProfileModel

  @ProfileModule.State('loggedIn')
  loggedIn!: ProfileModel

  @ProfileModule.Mutation('updateUserProfile')
  updateUserProfile!: Function

  @ProfileModule.Action('updateProfileRequest')
  updateProfileRequest!: Function

  @ProfileModule.Action('fetchProfile')
  fetchProfile!: Function

  @ProfileModule.State('disciplines')
  disciplines!: string[]

  @ProfileModule.Action('updateDisciplines')
  updateDisciplines!: Function

  updatingProfile: ProfileModel = new ProfileModel()

  TITLES = TITLES

  async created() {
    this.updateDisciplines()
    await this.fetchProfile()
    this.updatingProfile = this.userProfile.deepCopy()
  }

  async save() {
    await this.updateUserProfile(this.updatingProfile)
    await this.updateProfileRequest()
    await this.$router.push({
      name: RouteNames.PROFILE_WITH_NOTIFICATION,
    })
  }

  get userNameIsEmail() {
    return this.userProfile.username === this.userProfile.emailAddress
  }

  get userNameIsMobileNumber() {
    return this.userProfile.username === this.userProfile.mobileNumber
  }

  get userNameIsCustom() {
    return !this.userNameIsEmail && !this.userNameIsMobileNumber
  }

  @Watch('loggedIn')
  async checkIfLoggedIn() {
    if (!this.loggedIn) {
      await this.$router.replace({
        name: RouteNames.LOGIN,
      })
    }
  }
}
