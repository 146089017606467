





















































































































































































































































































































































import { Component } from 'vue-property-decorator'
import DefinitionList from '@/views/DefinitionList.vue'
import DefinitionModel from '@/models/general/definition-model'
import { mixins } from 'vue-class-component'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'

@Component({
  components: {
    DefinitionList,
  },
})
export default class PrivacyPolicy extends mixins(EnvironmentUrlMixin) {
  updateDate = new Date('April 1, 2021')

  definitionList: DefinitionModel[] = [
    new DefinitionModel('Account', 'means a unique account created for You to access our Service or parts of our Service.'),
    new DefinitionModel('Company', '(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to CircaMed.'),
    new DefinitionModel('Cookies', 'are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.'),
    new DefinitionModel('Country', 'refers to: South Africa.'),
    new DefinitionModel('Device', 'means any device that can access the Service such as a computer, a cellphone or a digital tablet.'),
    new DefinitionModel('Personal Data', 'is any information that relates to an identified or identifiable individual.'),
    new DefinitionModel('Service', 'refers to the Website.'),
    new DefinitionModel('Service Provider', 'means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.'),
    new DefinitionModel('Third-party Social Media Service', 'refers to any website or any social network website through which a User can log in or create an account to use the Service.'),
    new DefinitionModel('Usage Data', 'refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).'),
    // TODO get final link for below
    new DefinitionModel('Website', 'refers to CircaMed, accessible from '),
    new DefinitionModel('You', 'means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.'),
  ]

  trackingTechnologyDefinitions: DefinitionModel[] = [
    new DefinitionModel('Cookies or Browser Cookies.', ' A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.'),
    new DefinitionModel('Flash Cookies.', 'Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how You can delete Flash Cookies, please read "Where can I change the settings for disabling, or deleting local shared objects?" available at https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_'),
    new DefinitionModel('Web Beacons.', 'Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).'),
  ]

  dataUsePurposes: DefinitionModel[] = [
    new DefinitionModel('To provide and maintain our Service', ', including to monitor the usage of our Service.'),
    new DefinitionModel('To manage Your Account:', 'to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.'),
    new DefinitionModel('For the performance of a contract:', ' the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.'),
    new DefinitionModel('To contact You:', ' To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application\'s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.'),
    new DefinitionModel('To provide You', 'with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.'),
    new DefinitionModel('To manage Your requests: ', 'To attend and manage Your requests to Us.'),
    new DefinitionModel('For business transfers:', ' We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.'),
    new DefinitionModel('For other purposes:', 'We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.'),
  ]

  shareInfoCases: DefinitionModel[] = [
    new DefinitionModel('With Service Providers:', 'We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.'),
    new DefinitionModel('For business transfers:', 'We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.'),
    new DefinitionModel('With Affiliates:', 'We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.'),
    new DefinitionModel('With business partners:', 'We may share Your information with Our business partners to offer You certain products, services or promotions.'),
    new DefinitionModel('With other users:', 'when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.'),
    new DefinitionModel('With Your consent:', 'We may disclose Your personal information for any other purpose with Your consent.'),
  ]
}
