


















import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import screenSizeMixin from '@/mixins/screen-size-mixin'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'

@Component
export default class Icon extends mixins(screenSizeMixin, EnvironmentUrlMixin) {
  @Prop() iconFile!: string

  @Prop({ default: '1em' }) size!: string
}
