






















































































import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import screenSizeMixin from '@/mixins/screen-size-mixin'
import OfferingModel from '@/models/offering/offering-model'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'

@Component
export default class RecommendationCard extends mixins(screenSizeMixin, EnvironmentUrlMixin) {
  @Prop() offering!: OfferingModel
}
