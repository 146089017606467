






























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Select extends Vue {
  @Prop() readonly label!: string

  @Prop({ type: Boolean }) dark!: boolean

  @Prop() items!: Array<string>

  @Prop() itemText!: string

  @Prop() value!: string

  @Prop({ type: Boolean }) required!: boolean

  @Prop() rules!: Array<Function>
}
