var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('div',{ref:"textFieldTitle",class:{
      'field--label text--label-responsive text--colour-primary': true,
      'field--color-dark': _vm.dark
    }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('v-textarea',{class:{
      'form--field': true,
      'text--body-responsive': true,
      'field--background-light': !_vm.dark,
      'field--background-dark': _vm.dark,
     },attrs:{"dense":"","filled":"","flat":"","solo":"","auto-grow":"","rules":_vm.rules,"type":_vm.type,"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }