export default class ErrorModel {
  statusCode: number

  statusText: string

  message: string

  constructor(statusCode: number, statusText: string, message: string) {
    this.statusCode = statusCode
    this.statusText = statusText
    this.message = message
  }
}
