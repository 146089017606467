































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FormCard extends Vue {
  @Prop({ type: Boolean }) dark!: boolean

  @Prop({ type: Boolean }) hasProgressBar!: boolean

  @Prop() currentStep!: number

  @Prop() progress!: number
}
