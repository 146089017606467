

































import { Component, Prop, Vue } from 'vue-property-decorator'
import RouteNames from '@/router/route-names'

@Component
export default class StepperButtons extends Vue {
  @Prop({ type: Boolean }) onlyContinue!: boolean

  @Prop({ type: Boolean }) toProfile!: boolean

  @Prop({ type: Boolean }) skipStep!: boolean

  @Prop({ type: Boolean }) disabled!: boolean

  next() {
    if (this.toProfile) {
      this.$router.push({
        name: RouteNames.PROFILE_WITH_NOTIFICATION,
      })
    } else {
      this.$emit('next')
    }
  }

  get nextBtnText() {
    if (this.skipStep) {
      return 'Skip'
    }
    if (this.toProfile) {
      return 'Profile'
    }
    return 'Continue'
  }
}
