var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"imageUpload",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text--subtitle-responsive text--colour-primary"},[_vm._v(_vm._s(_vm.title))]),_c('v-row',{staticClass:"mt-3",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":_vm.uploadSlotOnly? 9:8}},[_c('v-card',{staticClass:"card",attrs:{"width":_vm.uploadSlotOnly? '375px':'200px'}},[_c('v-img',{class:{
              'fullPicture': _vm.value.byteLength > 0 || _vm.verified,
              'imgPlaceHolder': !_vm.value.byteLength,
              'imgPlaceHolderSmaller':!_vm.uploadSlotOnly && !_vm.value.byteLength,
              },attrs:{"aspect-ratio":1,"src":_vm.profilePicture,"gradient":_vm.imageGradient}},[(_vm.verifying || _vm.verified)?_c('v-row',{staticStyle:{"height":"100%"},attrs:{"justify":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"value":_vm.verified? 100:0,"indeterminate":!_vm.verified,"size":70,"width":5,"color":"primary"}},[(_vm.verified)?_c('v-icon',{attrs:{"color":"primary","x-large":""},domProps:{"textContent":_vm._s('mdi-check')}}):_vm._e()],1)],1):_vm._e()],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{class:{
                'selectFileButton':true,
                'disabledSelectFileButton':_vm.verifying || _vm.verified
              }},[_c('input',{ref:"fileInput",attrs:{"hidden":"","type":"file","accept":"image/*","required":""},on:{"change":function($event){return _vm.uploadImage($event)}}}),_c('v-container',{on:{"click":_vm.selectFile}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])],1),(!_vm.galleryOnly)?_c('div',{staticClass:"selectFileButtonSpacer"}):_vm._e(),(!_vm.galleryOnly)?_c('v-col',{staticClass:"selectFileButton"},[_c('v-container',[_vm._v("Camera")])],1):_vm._e()],1)],1)],1),(!_vm.uploadSlotOnly)?_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text--body-responsive text--colour-dark"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"mt-2"},[_vm._t("status")],2),(_vm.loadImageError !== '')?_c('div',{staticClass:"text--error-responsive mt-2"},[_vm._v(" "+_vm._s(_vm.loadImageError)+" ")]):_vm._e(),_vm._l((_vm.errorMessages),function(error,index){return _c('div',{key:(error + " " + index),staticClass:"text--error-responsive mt-2"},[_vm._v(" "+_vm._s(error)+" ")])})],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }