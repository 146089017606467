class RouteNames {
  ABOUT = 'About'

  BROKER = 'Broker'

  CAREERS = 'Careers'

  CATEGORY = 'Category'

  CONTACT = 'Contact'

  COOKIES_EXPLANATION = 'CookieExplanation'

  DISCLAIMER = 'Disclaimer'

  EMAIL_VERIFIED = 'EmailAddressVerified'

  HELP = 'Help'

  HOME = 'Home'

  LOGIN = 'Login'

  LOGBOX_ACADEMIC = 'LogBoxAcademic'

  LOGBOX_PRACTICE = 'LogBoxPractice'

  LOGBOX_SIGN_UP = 'LogBoxSignUp'

  NEW_PASSWORD = 'NewPassword'

  OFFERING = 'Offering'

  ORDERS = 'Orders'

  PASSWORD_MANAGEMENT = 'PasswordManagement'

  PASSWORD_RESET = 'PasswordReset'

  PAYMENTS = 'Payments'

  PRIVACY_POLICY = 'PrivacyPolicy'

  PROFILE_WITH_NOTIFICATION = 'ProfileWithVerifiedNotification'

  PROFILE_WITHOUT_NOTIFICATION = 'ProfileWithoutVerifiedNotification'

  EDIT_PROFILE = 'EditProfile'

  RECOMMENDATIONS = 'Recommendations'

  SIGN_UP = 'SignUp'

  TERMS_AND_CONDITIONS = 'TermsAndConditions'

  VERIFICATION_PROFILE = 'VerificationProfile'

  VERIFICATION_PRACTICE = 'VerificationPractice'

  VERIFICATION_PICTURE = 'VerificationPicture.vue'

  VERIFICATION_ID = 'VerificationID'

  VERIFICATION_HPCSA = 'VerificationHPCSA'

  VERIFICATION_STEPPER = 'Verification Stepper'

  SIGN_UP_VERIFICATION_EMAIL_SENT = 'Verification Email Sent'
}

export default new RouteNames()
