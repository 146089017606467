import Vue from 'vue'
import Vuex from 'vuex'
import ProviderStore from '@/store/modules/provider/provider-store'
import OfferingStore from '@/store/modules/offering/offering-store'
import ErrorStore from '@/store/modules/error-store'
import CategoryStore from '@/store/modules/offering/category-store'
import NeedsCalculatorFormStore from '@/store/modules/needscalculator/needs-calculator-form-store'
import LeadStore from '@/store/modules/lead/lead-store'
import FeatureFlagStore from '@/store/modules/feature/feature-flag-store'
import ContactUsStore from '@/store/modules/contactus/contact-us-store'
import LoadingIndicatorStore from '@/store/modules/loading-indicator-store'
import ProfileStore from '@/store/modules/profile/profile-store'
import NotificationStore from '@/store/modules/notification/notification-store'
import ShoppingCartStore from '@/store/modules/paymentportal/shopping-cart-store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    categoryStore: CategoryStore,
    providerStore: ProviderStore,
    leadStore: LeadStore,
    offeringStore: OfferingStore,
    errorStore: ErrorStore,
    needsCalculatorFormStore: NeedsCalculatorFormStore,
    featureFlagStore: FeatureFlagStore,
    contactUsStore: ContactUsStore,
    loadingIndicatorStore: LoadingIndicatorStore,
    profileStore: ProfileStore,
    notificationStore: NotificationStore,
    shoppingCartStore: ShoppingCartStore,
  },
})
