import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  namespaced: true,
  name: 'LoadingIndicatorStore',
})
export default class LoadingIndicatorStore extends VuexModule {
  display = false

  @Mutation
  showLoadingIndicator(value: boolean) {
    this.display = value
  }
}
