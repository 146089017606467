var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-theme-provider',{attrs:{"root":""}},[(_vm.offering)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
      name: _vm.ROUTES.OFFERING,
      params: {
        categoryId: _vm.categoryId,
        offeringId: _vm.offering.id.toString(),
        categoryName: _vm.categoryName
      }
  }}},[_c('v-row',{staticClass:"pa-5",attrs:{"justify":"center"}},[_c('v-card',{staticClass:"topCard",attrs:{"height":"85%","width":_vm.$vuetify.breakpoint.sm ? '70%': '100%'}},[_c('v-layout',[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('v-layout',{attrs:{"justify-end":""}},[_c('v-img',{attrs:{"eager":"","max-width":"45","alt":((_vm.offering.name) + " descriptive companies logo"),"src":(_vm.environmentUrl + "/" + _vm.logo)}})],1),_c('v-layout',{staticClass:"pb-2",attrs:{"justify-center":""}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('h1',{staticClass:"offeringName"},[_vm._v(_vm._s(_vm.offering.name))])])],1),_c('v-layout',{staticClass:"pb-5",attrs:{"justify-center":""}},[_c('div',{staticClass:"underline"})]),_c('v-layout',{staticClass:"pb-5",attrs:{"justify-center":""}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-img',{staticClass:"offeringImage",attrs:{"eager":"","aspect-ratio":"1","max-width":_vm.$vuetify.breakpoint.mdAndUp ? '60%' : '70%',"alt":((_vm.offering.name) + " descriptive image"),"src":(_vm.environmentUrl + "/" + (_vm.offering.image))},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"loading":"","max-width":"300","type":"image"}})]},proxy:true}],null,false,3288551395)})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"bottomButtonCard",attrs:{"height":"15%","width":_vm.$vuetify.breakpoint.sm ? '70%' : '100%'}},[_c('div',{staticClass:"bottomButton"},[(_vm.offering.canPurchase)?_c('v-icon',{attrs:{"color":"white"},domProps:{"textContent":_vm._s(_vm.$vuetify.icons.values.play)}}):_c('div',[_vm._v(" "+_vm._s(_vm.offering.price)+" ")])],1)])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }