import {
  Module, Mutation, MutationAction, VuexModule,
} from 'vuex-module-decorators'
import axios from 'axios'
import ProviderModel from '@/models/provider/provider-model'
import PlatformModel from '@/models/provider/platform-model'
import ProviderStoreStateModel from '@/models/store/states/provider-store-state-model'

@Module({
  namespaced: true,
  name: 'ProviderStore',
})
export default class ProviderStore extends VuexModule {
  providersPerCategory: Map<string, number[]> = new Map()

  provider: ProviderModel = new ProviderModel()

  platform: PlatformModel = new PlatformModel()

  providerBatch = new Array<ProviderModel>()

  @Mutation
  resetProvider() {
    this.provider = new ProviderModel()
  }

  @MutationAction({ mutate: ['providersPerCategory'] })
  async getProviderIdsPerCategory(categoryId: number) {
    const currentState = this.state as ProviderStoreStateModel
    const ret = new Map(currentState.providersPerCategory)

    const response = await axios.get(`/api/providers/category/${categoryId}`)
    const sorted = response.data.sort((a: number, b: number) => a - b)
    ret.set(categoryId.toString(), sorted as number[])
    return { providersPerCategory: ret }
  }

  @MutationAction({ mutate: ['platform'] })
  async getCircamedPlatform() {
    const response = await axios.get('/api/providers/circamed')
    return { platform: response.data as PlatformModel }
  }

  @MutationAction({ mutate: ['providerBatch'] })
  async getBatchOfProviders(ids: number[]) {
    const response = await axios.get('/api/providers/byIds', {
      params: {
        ids: ids.toString(),
      },
    })

    return { providerBatch: response.data as ProviderModel[] }
  }

  @MutationAction({ mutate: ['provider'] })
  async updateProviderById(id: number) {
    const response = await axios.get(`/api/providers/${id}`)
    return { provider: response.data as ProviderModel }
  }

  @MutationAction({ mutate: ['provider'] })
  async updateProviderByName(name: string) {
    return axios.get(`/api/providers/byName/${name}`)
      .then((response) => ({ provider: response.data as ProviderModel }))
      .catch(() => ({ provider: new ProviderModel() }))
  }
}
