
























import { Component } from 'vue-property-decorator'
import EnvironmentUrlMixin from '@/mixins/environment-url-mixin'
import { mixins } from 'vue-class-component'

@Component
export default class TermsAndConditions extends mixins(EnvironmentUrlMixin) {
  updateDate = new Date('April 6, 2021')
}
