




















































































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import FormCard from '@/components/form/FormCard.vue'
import IconWithText from '@/components/form/IconWithText.vue'
import ProfileModel from '@/models/profile/profile-model'
import DividerWithText from '@/components/form/DividerWithText.vue'
import { namespace } from 'vuex-class'
import RouteNames from '@/router/route-names'
import SupportEmail from '@/components/general/SupportEmail.vue'
import IdentityService from '@/services/api/identity-service'

const NotificationModule = namespace('notificationStore')
const ProfileModule = namespace('profileStore')
const LoadingIndicatorModule = namespace('loadingIndicatorStore')

@Component({
  components: {
    FormCard,
    IconWithText,
    DividerWithText,
    SupportEmail,
  },
})
export default class Profile extends Vue {
  @ProfileModule.State('userProfile')
  userProfile!: ProfileModel

  @ProfileModule.State('loggedIn')
  loggedIn!: boolean

  @ProfileModule.Action('logoutRequest')
  logoutRequest!: Function

  @ProfileModule.Action('fetchProfile')
  fetchProfile!: Function

  @NotificationModule.Mutation('updateMessage')
  updateMessage!: Function

  @NotificationModule.Mutation('updateColor')
  updateColor!: Function

  @LoadingIndicatorModule.Mutation('showLoadingIndicator')
  showLoadingIndicator!: Function

  @Prop({ type: Boolean, default: false })
  showNotification!: boolean

  canRedirect = false

  verificationEmailSending = false

  resendEmailError = false

  async created() {
    if (this.loggedIn) {
      this.showLoadingIndicator(true)
      await this.fetchProfile()
      if (this.showNotification) {
        if (this.profileVerified) {
          this.updateColor('green')
          this.updateMessage('You have been successfully verified')
        } else {
          this.updateColor('accent')
          this.updateMessage('You are not yet verified. Please complete the verification process to gain access to all the benefits Circamed has to offer.')
        }
      }
      this.canRedirect = true
      this.showLoadingIndicator(false)
    } else {
      await this.$router.replace({
        name: RouteNames.LOGIN,
      })
    }
  }

  get profileVerified(): boolean {
    return this.userProfile.isHpcsaDocumentVerified
      && this.userProfile.isIdPassportDocumentVerified
      && this.userProfile.isEmailAddressVerified
  }

  get profileName() {
    let firstName = this.userProfile.firstName.split(' ')[0].toLowerCase()
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
    let lastName = this.userProfile.lastName.toLowerCase()
    lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1)
    if (this.userProfile.title === null) {
      return `${firstName} ${lastName}`
    }
    return `${this.userProfile.title} ${firstName} ${lastName}`
  }

  get fullName() {
    let fullReturnName = ''
    const names = this.userProfile.firstName.toLowerCase().split(' ')
    names.push(this.userProfile.lastName.toLowerCase())

    names.forEach((name) => {
      fullReturnName += `${name.charAt(0).toUpperCase() + name.slice(1)} `
    })

    return fullReturnName
  }

  async logout() {
    await this.logoutRequest()
  }

  async restartVerification() {
    if (this.userProfile.isEmailAddressVerified) {
      await this.$router.push({
        name: RouteNames.VERIFICATION_PROFILE,
      })
    } else {
      this.verificationEmailSending = true
      try {
        await IdentityService.postVerifyEmailAddress()
        await this.$router.push({
          name: RouteNames.SIGN_UP_VERIFICATION_EMAIL_SENT,
        })
      } catch (e) {
        this.resendEmailError = true
      }
      this.verificationEmailSending = false
    }
  }

  @Watch('loggedIn')
  async checkIfLoggedIn() {
    if (!this.loggedIn && this.canRedirect) {
      await this.$router.replace({
        name: RouteNames.LOGIN,
      })
    }
  }
}
