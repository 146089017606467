


































import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { getError } from '@/services/error-service'
import { LOGBOX_ACADEMIC, LOGBOX_PRACTICE } from '@/services/logbox-service'
import OfferingDetail from '@/components/offering/detail/OfferingDetail.vue'
import DoubleFormCard from '@/components/form/DoubleFormCard.vue'
import ConnectToABrokerForm from '@/components/offering/detail/ConnectToABrokerForm.vue'
import GetStartedForm from '@/components/offering/detail/GetStartedForm.vue'
import OfferingModel from '@/models/offering/offering-model'
import ErrorModel from '@/models/error-model'

const OfferingModule = namespace('offeringStore')
const ErrorModule = namespace('errorStore')
const LoadingIndicatorModule = namespace('loadingIndicatorStore')

@Component({
  components: {
    GetStartedForm,
    ConnectToABrokerForm,
    DoubleFormCard,
    OfferingDetail,
  },
})
export default class DetailPage extends Vue {
  @Prop() offeringId!: number

  @Prop() categoryName!: string

  @Prop() categoryId!: number

  @OfferingModule.State('offering') offering!: OfferingModel

  @ErrorModule.Mutation('updateError') setError!: Function

  @OfferingModule.Action('loadOffering') loadOffering!: Function

  @LoadingIndicatorModule.Mutation('showLoadingIndicator')
  showLoadingIndicator!: Function

  private image: string | undefined = undefined

  private logo = ''

  private successfulRequest = false

  private displayLogBoxLink = false

  private isLogBoxPractice = false

  async created() {
    this.showLoadingIndicator(true)

    try {
      await this.loadOffering(this.offeringId)
      this.image = this.offering.image
      if (this.offering.provider) {
        this.logo = this.offering.provider.logo
      }
      this.successfulRequest = true
    } catch (error) {
      const errorConstant: ErrorModel = getError(error.response.status)
      if (errorConstant.statusCode === 0) {
        errorConstant.statusCode = error.response.status
        errorConstant.statusText = error.response.statusText
      }
      this.setError(errorConstant)
    }

    if (this.offering.name?.toLowerCase().includes(LOGBOX_PRACTICE.toLowerCase())) {
      this.displayLogBoxLink = true
      this.isLogBoxPractice = true
    } else if (this.offering.name?.toLowerCase().includes(LOGBOX_ACADEMIC.toLowerCase())) {
      this.displayLogBoxLink = true
      this.isLogBoxPractice = false
    }

    this.showLoadingIndicator(false)
  }
}
