import axios from 'axios'
import {
  Module, Mutation, MutationAction, VuexModule,
} from 'vuex-module-decorators'
import OfferingModel from '@/models/offering/offering-model'
import OfferingStoreStateModel from '@/models/store/states/offerings-store-state-model'
import OfferingPaginationModel from '@/models/offering/offering-pagination-model'

@Module({
  namespaced: true,
  name: 'OfferingStore',
})
export default class OfferingStore extends VuexModule {
  offering: OfferingModel = new OfferingModel()

  offeringsByCategory: Map<string, OfferingPaginationModel> = new Map()

  offeringDescriptions: OfferingModel[] = []

  recommendedOfferings: OfferingModel[] = []

  @Mutation
  resetOffering() {
    this.offering = new OfferingModel()
  }

  @Mutation
  resetRecommendedOfferings() {
    this.recommendedOfferings = []
  }

  @Mutation
  resetOfferingsByCategory(categoryName: string) {
    this.offeringsByCategory.delete(categoryName)
  }

  @MutationAction({ mutate: ['recommendedOfferings'] })
  async appendRecommendedOfferings(idsString: string) {
    const state = this.state as OfferingStoreStateModel
    const response = await axios.get('/api/offerings/image/byIds', { params: { ids: idsString } })
    return {
      recommendedOfferings: state.recommendedOfferings.concat(
        response.data.content as OfferingModel[],
      ),
    }
  }

  @MutationAction({ mutate: ['offering'] })
  async loadOffering(id: number) {
    const response = await axios.get(`/api/offerings/${id}`)
    return { offering: response.data as OfferingModel }
  }

  @MutationAction({ mutate: ['offeringsByCategory'] })
  async loadOfferingsByCategory(info: OfferingPaginationModel) {
    const response = await axios.get(
      '/api/offerings/image/byIds', {
        params: {
          ids: info.ids.toString(),
          page: info.page,
          size: info.batchSize,
        },
      },
    )

    const currentState = this.state as OfferingStoreStateModel
    const offeringMap = new Map(currentState.offeringsByCategory)
    const offerings: OfferingModel[] = offeringMap.get(info.keyName)?.offerings || []

    response.data.content.forEach((it: OfferingModel) => {
      if (!offerings.find((offering) => offering.id === it.id)) {
        offerings.push(it)
      }
    })

    const offeringPaginationModel: OfferingPaginationModel = new OfferingPaginationModel(
      info.keyName,
      info.page + 1,
      response.data.totalPages,
      info.batchSize,
      info.ids,
      offerings,
    )
    offeringMap.set(info.keyName, offeringPaginationModel)

    return { offeringsByCategory: offeringMap }
  }

  @MutationAction({ mutate: ['offeringDescriptions'] })
  async loadOfferingDescriptions(idsString: string) {
    const response = await axios.get(
      '/api/offerings/description/byIds', { params: { ids: idsString } },
    )
    return { offeringDescriptions: response.data as OfferingModel[] }
  }
}
