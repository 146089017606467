import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ScreenSizeMixin extends Vue {
  get isDesktop() {
    return this.$vuetify.breakpoint.lgAndUp
  }

  get isSmallerDevice() {
    return this.$vuetify.breakpoint.smAndDown
  }
}
