







































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CATEGORY_NAMES } from '@/services/category-name-service'
import { namespace } from 'vuex-class'
import BasicOfferingCard from '@/components/offering/BasicOfferingCard.vue'
import ConnectBrokerCard from '@/components/lead/ConnectBrokerCard.vue'
import DropDownButton from '@/components/form/DropdownButton.vue'
import ProviderModel from '@/models/provider/provider-model'
import OfferingPaginationModel from '@/models/offering/offering-pagination-model'

const OfferingModule = namespace('offeringStore')

@Component({
  components: {
    DropDownButton,
    BasicOfferingCard,
    ConnectBrokerCard,
  },
})
export default class ProviderOfferings extends Vue {
  @Prop() provider!: ProviderModel

  @Prop() categoryId!: number

  @Prop() categoryName!: string

  @OfferingModule.Action('loadOfferingsByCategory')
  loadOfferingsByCategory!: Function

  @OfferingModule.State('offeringsByCategory')
  offeringsByCategory!: Map<string, OfferingPaginationModel>

  get offerings() {
    return this.offeringsByCategory.get(this.offeringKey)
  }

  get offeringKey() {
    return `${this.categoryName}-${this.provider.name}`
  }

  get categoryDescription() {
    if (this.categoryName === CATEGORY_NAMES.CMSA) {
      return 'Products And Services'
    }
    return `${this.categoryName}`
  }

  async loadNextOfferings() {
    const offeringPaginationModel: OfferingPaginationModel = this.offerings
      || new OfferingPaginationModel(
        (`${this.categoryName}-${this.provider.name}`),
        0,
        1,
        this.$vuetify.breakpoint.lgAndUp ? 4 : 3,
        this.provider.offeringIds,
        [],
      )

    await this.loadOfferingsByCategory(offeringPaginationModel)
  }
}
