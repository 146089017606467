import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  window.document.title = 'CircaMed'
  if (window.pageYOffset > 250) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }
  next();
})

export default router
