
















































import { Component, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { mixins } from 'vue-class-component'
import CircamedHeader from '@/components/header/CircamedHeader.vue'
import CircamedFooter from '@/components/footer/CircamedFooter.vue'
import ErrorComponent from '@/components/ErrorComponent.vue'
import LeadActionModel from '@/models/lead/lead-action-model'
import LeadActionPathModel from '@/models/lead/lead-action-path-model'
import screenSizeMixin from '@/mixins/screen-size-mixin'
import authInterceptorMixin from '@/mixins/auth-interceptor-mixin'
import SnackbarNotification from '@/components/general/SnackbarNotification.vue'

const ErrorModule = namespace('errorStore')
const LeadModule = namespace('leadStore')
const FeatureFlagModule = namespace('featureFlagStore')
const LoadingIndicatorModule = namespace('loadingIndicatorStore')
const ProfileModule = namespace('profileStore')

@Component({
  components: {
    CircamedHeader,
    CircamedFooter,
    ErrorComponent,
    SnackbarNotification,
  },
})
export default class App extends mixins(
  screenSizeMixin,
  authInterceptorMixin,
) {
  @ErrorModule.State('display') displayError!: boolean

  @LoadingIndicatorModule.State('display') displayLoadingIndicator!: boolean

  @LeadModule.Mutation('addToPath')
  addToPath!: Function

  @LeadModule.State('path')
  pathState!: string[]

  @ProfileModule.Action('authRequest')
  authRequest!: Function

  @FeatureFlagModule.Action('getAllFeatureFlags')
  getAllFeatureFlags!: Function

  async created() {
    this.createAuthInterceptor()
    this.addToPath(this.path)
    await this.getAllFeatureFlags()
    await this.authRequest()
  }

  get path(): LeadActionModel {
    return {
      type: 'url_path',
      path: this.$route.fullPath,
    } as LeadActionPathModel
  }

  @Watch('path')
  routeChanged(to: string) {
    this.addToPath(to)
  }
}
