import TagModel from '@/models/offering/tags/tag-model'

export default class TextTagModel extends TagModel {
   text = ''

   constructor(txt: string, name: string) {
     super('text_tag', name);
     this.text = txt
   }
}
